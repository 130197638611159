import React from 'react';

const styles = {
  container: {
    minHeight: '100vh',
    background: 'linear-gradient(to bottom, #f8f9fa, #ffffff)',
    padding: '48px 16px',
  },
  content: {
    maxWidth: '1000px',
    margin: '0 auto',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  header: {
    background: 'linear-gradient(to right, #3B82F6, #2563EB)',
    padding: '32px',
    textAlign: 'center',
    color: '#ffffff',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  date: {
    color: '#E0E7FF',
    fontStyle: 'italic',
  },
  intro: {
    padding: '32px',
    backgroundColor: '#EFF6FF',
    textAlign: 'center',
  },
  introText: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#1F2937',
  },
  sectionsContainer: {
    padding: '32px',
  },
  sectionGrid: {
    display: 'grid',
    gap: '24px',
  },
  section: {
    border: '1px solid #E5E7EB',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'box-shadow 0.3s ease',
  },
  sectionHeader: {
    backgroundColor: '#F9FAFB',
    padding: '16px 24px',
    borderBottom: '1px solid #E5E7EB',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#111827',
    margin: 0,
  },
  sectionContent: {
    padding: '24px',
  },
  itemsList: {
    display: 'grid',
    gap: '16px',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  item: {
    padding: '16px',
    backgroundColor: '#ffffff',
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    color: '#4B5563',
    lineHeight: '1.6',
    transition: 'all 0.3s ease',
  },
  contact: {
    borderTop: '1px solid #E5E7EB',
    padding: '32px',
    textAlign: 'center',
    background: 'linear-gradient(to right, #F9FAFB, #FFFFFF)',
  },
  email: {
    color: '#3B82F6',
    textDecoration: 'none',
    fontWeight: '500',
    transition: 'color 0.3s ease',
  }
};

const StaticPage = ({ type }) => {
  const termsData = {
    title: "Terms and Conditions",
    effectiveDate: "Nov 30, 2024",
    intro: "Welcome to Merche - Business! By downloading or using our app, you agree to the following terms. Please read them carefully.",
    sections: [
      {
        title: "1. Use of the App",
        items: [
          "The app is intended for businesses to manage orders, reviews, and product/services updates on our platform.",
          "You agree to use the app only for lawful purposes and in compliance with these Terms and applicable laws."
        ]
      },
      {
        title: "2. User Accounts",
        items: [
          "You must provide accurate and up-to-date information when registering.",
          "You are responsible for maintaining the confidentiality of your account credentials and all activities under your account."
        ]
      },
      {
        title: "3. Intellectual Property",
        items: [
          "The app and its content (logos, design, features) are the intellectual property of Merche - Business. Unauthorized use or reproduction is prohibited."
        ]
      },
      {
        title: "4. Limitation of Liability",
        items: [
          "Merche - Business is provided \"as is.\" We are not liable for any loss, damage, or interruption resulting from your use of the app."
        ]
      },
      {
        title: "5. Termination",
        items: [
          "We reserve the right to suspend or terminate your access to the app at any time for violation of these Terms or misuse."
        ]
      },
      {
        title: "6. Updates",
        items: [
          "The app may be updated from time to time. Continued use after updates constitutes acceptance of the updated Terms."
        ]
      }
    ]
  };

  const privacyData = {
    title: "Privacy Policy",
    effectiveDate: "30 Nov, 2024",
    intro: "At Merche - Business, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information.",
    sections: [
      {
        title: "1. Information We Collect",
        items: [
          "Business Information: Details like your business name, email, and address.",
          "Order and Product Data: Information about the orders received and product/service updates.",
          "Usage Data: Analytics about how you interact with the app to improve our services."
        ]
      },
      {
        title: "2. How We Use Your Information",
        items: [
          "To provide and improve our services.",
          "To notify you about updates or changes to the app.",
          "To ensure the app's security and performance."
        ]
      },
      {
        title: "3. Sharing Your Information",
        items: [
          "We do not sell your data to third parties.",
          "We may share data with trusted partners who assist in app functionality."
        ]
      },
      {
        title: "4. Data Security",
        items: [
          "We use industry-standard practices to protect your data. However, no method of electronic storage is 100% secure."
        ]
      },
      {
        title: "5. Your Rights",
        items: [
          "You may access, update, or delete your personal information by contacting us at info@coderythm.com.",
          "You can opt out of analytics tracking through the app settings."
        ]
      },
      {
        title: "6. Third-Party Links",
        items: [
          "The app may contain links to third-party websites. We are not responsible for their privacy practices."
        ]
      },
      {
        title: "7. Changes to This Policy",
        items: [
          "We may update this Privacy Policy from time to time. Continued use of the app constitutes acceptance of the updated policy."
        ]
      }
    ]
  };

  const documentData = type === "terms-and-conditions" ? termsData : privacyData;

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.header}>
          <h1 style={styles.title}>{documentData.title}</h1>
          <div style={styles.date}>Effective Date: {documentData.effectiveDate}</div>
        </div>

        <div style={styles.intro}>
          <p style={styles.introText}>{documentData.intro}</p>
        </div>

        <div style={styles.sectionsContainer}>
          <div style={styles.sectionGrid}>
            {documentData.sections.map((section, index) => (
              <div key={index} style={styles.section}>
                <div style={styles.sectionHeader}>
                  <h2 style={styles.sectionTitle}>{section.title}</h2>
                </div>
                <div style={styles.sectionContent}>
                  <ul style={styles.itemsList}>
                    {section.items.map((item, itemIndex) => (
                      <li key={itemIndex} style={styles.item}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div style={styles.contact}>
          <p>
            For any questions, contact us at{' '}
            <a
              href="mailto:contact@merche.app"
              style={styles.email}
              onMouseOver={e => e.target.style.color = '#2563EB'}
              onMouseOut={e => e.target.style.color = '#3B82F6'}
            >
              contact@merche.app
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StaticPage;