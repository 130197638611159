import React, { useEffect } from "react"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Paper from "@mui/material/Paper"
import { Button } from "@mui/material"
import { json, useParams } from "react-router-dom"
import "../../css/orders.css"
import { formatDate, formatCurrency } from "../../common/Services/functions"
import ListGroup from "react-bootstrap/ListGroup"
import { useNavigate } from "react-router-dom"
import {
  acceptOrder,
  OrderStatusUpdate,
  cancelOrder,
  sendEmailOrderCancellation,
  sendEmailOrderAccept,
  sendEmailInvoiceOrderConfirmation,
  createNote,
  noteDelete,
} from "common/Services/dbServices"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { LottieLoading } from "../../common/LottieLoading"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useOrder } from "common/Services/order"
import StorefrontIcon from "@mui/icons-material/Storefront"
import { Col, Card, CardBody } from "reactstrap"
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

const OrderDetail = () => {
  useEffect(() => {
    setOrderId(id)
  }, [])
  const {
    oneOrderDetails,
    oneOrderDetailsIsLoading,
    oneOrderDetailsRefetch,
    setOrderId,
    AcceptOrder,
    updateOrderStatus,
  } = useOrder()
  // console.log("oneOrderDetails=", oneOrderDetails)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [content, setContent] = React.useState("")
  const [openDialog, setOpenDialog] = React.useState(false)
  const [cancelLoading, setCancelLoading] = React.useState(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState("")
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success")
  const navigate = useNavigate()
  const { id } = useParams()

  if (oneOrderDetailsIsLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  if (!oneOrderDetails) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleAcceptOrder = async () => {
    setLoading(true)
    try {
      const result = await AcceptOrder()
      if (result) {
        oneOrderDetailsRefetch()
        setLoading(false)
        setShowSuccess(true)
        try {
          await sendEmailOrderAccept({ orderId: id })
          console.log("Accept email sent successfully.")
        } catch (emailError) {
          console.error("Error sending accept email:", emailError)
          alert("Order accepted, but email notification failed.")
        }
        setTimeout(() => {
          setShowSuccess(false)
        }, 3000)
      }
      oneOrderDetailsRefetch()
    } catch (error) {
      setLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }
  const handleOrderStatusUpdate = async ({ itemId, orderStatus }) => {
    setLoading(true)
    try {
      const result = await updateOrderStatus({ itemId, orderStatus })
      if (result) {
        oneOrderDetailsRefetch()
        setLoading(false)
        setShowSuccess(true)
        try {
          await sendEmailInvoiceOrderConfirmation({ orderId: itemId })
          console.log("Completed email sent successfully.")
        } catch (emailError) {
          console.error("Error sending order complete email:", emailError)
          alert("order Completed, but email notification failed.")
        }
        setTimeout(() => {
          setShowSuccess(false)
        }, 3000)
      }
    } catch (error) {
      setLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const handleCancelOrder = async () => {
    setCancelLoading(true)
    try {
      const result = await cancelOrder({
        id: id,
      })
      if (result?.modifiedCount > 0) {
        setOpenDialog(false)
        oneOrderDetailsRefetch()
        setCancelLoading(false)
        setSnackbarMessage("Order cancelled successfully!")
        setSnackbarSeverity("success")
        setSnackbarOpen(true)
        navigate("/orders")
        try {
          await sendEmailOrderCancellation({ orderId: id })
        } catch (emailError) {
          console.error("Error sending cancel email:", emailError)
          alert("Order cancelled, but email notification failed.")
        }
      } else {
        alert("Failed to cancel order. Please try again.")
      }
      oneOrderDetailsRefetch()
    } catch (error) {
      setCancelLoading(false)
      setSnackbarMessage("Error canceling the order. Please try again.")
      setSnackbarSeverity("error")
      setSnackbarOpen(true)
    }
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSuccess(false)
    setShowError(false)
  }

  const addNote = async () => {
    try {
      if (content?.length > 0) {
        const response = await createNote({
          orderId: oneOrderDetails?._id,
          content,
        })
        oneOrderDetailsRefetch()
        setContent("")
        // console.log("response ===", response)
        // console.log("length ===", content.length)
      } else {
      }
      // Note();
    } catch (e) {
      // console.log("Error ==", e)
    }
  }

  const deleteNote = async index => {
    // console.log("notes====", oneOrderDetails?.notes)

    let tempArray = oneOrderDetails?.notes
    // console.log("tempArray before", JSON.stringify(tempArray))
    const originalIndex = tempArray.length - 1 - index

    if (originalIndex >= 0 && originalIndex < tempArray.length) {
      tempArray.splice(originalIndex, 1)
    }
    // console.log("tempArray after", JSON.stringify(tempArray))
    try {
      const response = await noteDelete({
        orderId: oneOrderDetails?._id,
        tempArray,
      })
      oneOrderDetailsRefetch()
      // setContent("")
      // console.log("response ===", response)
      // Note();
    } catch (e) {
      // console.log("Error ==", e)
    }
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Order Status Changed
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>

      {/* Snackbar Component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Modal for Cancel Order Confirmation */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#f0f8ff",
            borderRadius: "12px",
            padding: "20px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontFamily: "outfit",
            fontSize: "1.2rem",
            color: "#333",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Cancel Order
        </DialogTitle>
        <DialogContent
          sx={{
            fontFamily: "outfit",
            fontSize: "1rem",
            color: "#444",
            textAlign: "center",
          }}
        >
          Are you sure you want to cancel this order?
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            paddingTop: "15px",
          }}
        >
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            sx={{
              fontFamily: "outfit",
              backgroundColor: "#d32f2f",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#b71c1c",
              },
            }}
          >
            No
          </Button>
          {cancelLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              sx={{
                fontFamily: "outfit",
                color: "#1976d2",
                borderColor: "#1976d2",
              }}
            >
              Yes
            </LoadingButton>
          ) : (
            <Button
              onClick={handleCancelOrder}
              color="secondary"
              autoFocus
              disabled={cancelLoading}
              sx={{
                fontFamily: "outfit",
                backgroundColor: "#1976d2",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <div className="order-details" style={{ marginTop: "20px" }}>
        <div className="order_update_btn_div">
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
            }}
            style={{ fontFamily: "outfit", margin: "8px" }}
            variant="contained"
            onClick={() => {
              navigate(-1 || `/orders`)
            }}
          >
            Back
          </Button>

          {oneOrderDetails?.status == "pending" ? (
            <div style={{ display: "flex", gap: "10px" }}>
              {/* Cancel Order Button */}
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 130,
                    height: 35,
                    backgroundColor: "#d32f2f",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#d32f2f",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#d32f2f",
                  },
                }}
                style={{ fontFamily: "outfit", color: "white" }}
                variant="contained"
                onClick={() => setOpenDialog(true)}
              >
                Cancel Order
              </Button>

              {/* Accept Order Button */}
              {loading ? (
                <LoadingButton
                  size="small"
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      width: 130,
                      height: 35,
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                    },
                  }}
                  style={{ fontFamily: "outfit" }}
                  variant="contained"
                >
                  <span> Accept Order</span>
                </LoadingButton>
              ) : (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      width: 130,
                      height: 35,
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                      backgroundColor: "#333547",
                    },
                  }}
                  style={{ fontFamily: "outfit" }}
                  variant="contained"
                  onClick={() => handleAcceptOrder()}
                >
                  Accept Order
                </Button>
              )}
            </div>
          ) : (
            ""
          )}

          {oneOrderDetails?.status == "accepted" ? (
            loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 130,
                    height: 35,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
              >
                <span>
                  {oneOrderDetails.orderType === "inStorePickup"
                    ? "Prepared for Pickup"
                    : "Mark As Ready"}
                </span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 130,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                onClick={() =>
                  handleOrderStatusUpdate({
                    itemId: id,
                    orderStatus: "ready",
                  })
                }
              >
                <span>
                  {oneOrderDetails.orderType === "inStorePickup"
                    ? "Prepared for Pickup"
                    : "Mark As Ready"}
                </span>
              </Button>
            )
          ) : (
            ""
          )}

          {oneOrderDetails?.status == "ready" ? (
            loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                }}
                style={{ fontFamily: "outfit" }}
              >
                <span> Mark As Completed</span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                onClick={() =>
                  handleOrderStatusUpdate({
                    itemId: id,
                    orderStatus: "completed",
                  })
                }
              >
                Mark As Completed
              </Button>
            )
          ) : (
            ""
          )}

          {oneOrderDetails?.status == "completed" ? (
            loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                }}
                style={{ fontFamily: "outfit" }}
              >
                <span> Mark As Completed</span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                onClick={() => {
                  navigate(`/${id}/invoice`)
                }}
              >
                Invoice
              </Button>
            )
          ) : (
            ""
          )}
        </div>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.7rem",
                  width: "50%",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  width: "50%",
                },
                "@media (min-width:  970px) ": {
                  fontSize: "0.9rem",
                  width: "50%",
                },
              }}
            >
              Order Details
            </Typography>
            {oneOrderDetails.orderType === "inStorePickup" ? (
              <Typography
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.7rem",
                    fontWeight: "500",
                    width: "50%",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    width: "50%",
                  },
                  "@media (min-width:  970px) ": {
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    width: "50%",
                  },
                }}
              >
                <StorefrontIcon /> In Store Pickup
              </Typography>
            ) : null}
          </AccordionSummary>
          <AccordionDetails>
            <ListGroup>
              <ListGroup.Item>
                <div
                  className="order-detail-row"
                  style={{ fontFamily: "outfit" }}
                >
                  <div className="order-detail-element">
                    <h6>Reference:</h6>
                    <h6>{oneOrderDetails?.refNumber}</h6>
                  </div>
                  <div className="order-detail-element">
                    <h6>Date:</h6>
                    <h6>{formatDate(oneOrderDetails?.date)}</h6>
                  </div>
                </div>
              </ListGroup.Item>

              <ListGroup.Item>
                <div
                  className="order-detail-row"
                  style={{ fontFamily: "outfit" }}
                >
                  <div className="order-detail-element">
                    <h6>Order Type:</h6>
                    <h6>{oneOrderDetails?.orderType}</h6>
                  </div>
                  <div className="order-detail-element">
                    <h6>Order Status:</h6>
                    <h6>{oneOrderDetails?.status}</h6>
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div
                  className="order-detail-row"
                  style={{ fontFamily: "outfit" }}
                >
                  <div className="order-detail-element">
                    <h6>Payment Status:</h6>
                    <h6>{oneOrderDetails?.paymentStatus}</h6>
                  </div>
                  <div className="order-detail-element">
                    <h6>Payment Type:</h6>
                    <h6>{oneOrderDetails?.paymentType}</h6>
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div
                  className="order-detail-row"
                  style={{ fontFamily: "outfit" }}
                >
                  <div className="order-detail-element">
                    <h6>Delivery Charge:</h6>
                    <h6>
                      {formatCurrency(
                        oneOrderDetails?.payments?.deliveryCharge || 0
                      )}
                    </h6>
                  </div>
                  <div className="order-detail-element">
                    <h6>Additional Charges:</h6>
                    <h6>
                      {formatCurrency(
                        oneOrderDetails?.payments?.additionalCharges || 0
                      )}
                    </h6>
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div
                  className="order-detail-row"
                  style={{ fontFamily: "outfit" }}
                >
                  <div className="order-detail-element">
                    <h6>Item Total Amount:</h6>
                    <h6>
                      {formatCurrency(
                        oneOrderDetails?.payments?.itemTotal || 0
                      )}
                    </h6>
                  </div>
                  <div className="order-detail-element">
                    <h6>Discount:</h6>
                    <h6>
                      {formatCurrency(oneOrderDetails?.payments?.discount || 0)}
                    </h6>
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div
                  className="order-detail-row"
                  style={{ fontFamily: "outfit" }}
                >
                  <div className="order-detail-element">
                    <h6>Total Amount Payable:</h6>
                    <h6>
                      {formatCurrency(
                        oneOrderDetails?.amount ||
                          oneOrderDetails?.payments?.amountPayable ||
                          0
                      )}
                    </h6>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.7rem",
                  textAlign: "center",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  textAlign: "center",
                },
                "@media (min-width:  970px) ": {
                  fontSize: "0.9rem",
                  textAlign: "center",
                },
              }}
            >
              Ordered Products
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ListGroup>
              <ListGroup.Item style={{ padding: 0 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={tableHeadStyle}
                          align="left"
                          style={{ fontFamily: "outfit" }}
                        >
                          Item
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Variants
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Add Ons
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {oneOrderDetails?.items?.map(row => (
                        <TableRow key={row?._id}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {`${row?.name?.en}`}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                              }}
                            >
                              {row?.variantOptions?.map((option, index) => (
                                <p
                                  className="order-variant-p"
                                  key={index}
                                >{`${option?.variantLabel}: ${option?.optionLabel},`}</p>
                              ))}
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {`${formatCurrency(
                              row?.salePrice || row?.price
                            )} ( per item)`}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {row?.quantity}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {row?.modifiers?.map((value, index) => (
                              // console.log(value?.modifierName?.en)
                              <h6
                                className="order-addon-h6"
                                key={index}
                              >{`${value?.modifierName?.en},`}</h6>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ListGroup.Item>{" "}
            </ListGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.7rem",
                  textAlign: "center",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  textAlign: "center",
                },
                "@media (min-width:  970px) ": {
                  fontSize: "0.9rem",
                  textAlign: "center",
                },
              }}
            >
              Customer Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ListGroup>
              <ListGroup.Item style={{ padding: 0 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={tableHeadStyle}
                          align="left"
                          style={{ fontFamily: "outfit" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Mobile
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          sx={tableHeadStyle}
                          align="center"
                          style={{ fontFamily: "outfit" }}
                        >
                          Shipping Address
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >{`${oneOrderDetails?.customer?.firstName} ${oneOrderDetails?.customer?.lastName}`}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >{`+91 ${oneOrderDetails?.customer?.number}`}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {oneOrderDetails?.customer?.email}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {" "}
                          {oneOrderDetails?.address?.address?.toLowerCase()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </ListGroup.Item>{" "}
            </ListGroup>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.7rem",
                  textAlign: "center",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  textAlign: "center",
                },
                "@media (min-width:  970px) ": {
                  fontSize: "0.9rem",
                  textAlign: "center",
                },
              }}
            >
              Timeline
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Activity</h4>
                  <ol className="activity-feed">
                    {oneOrderDetails?.orderTrackingStatus?.orderPlaced ? (
                      <li className="feed-item">
                        <div className="feed-item-list">
                          <span
                            className="date"
                            style={{
                              color: "black",
                              fontWeight: "400",
                              fontSize: "0.7rem",
                            }}
                          >
                            {formatDate(
                              oneOrderDetails?.orderTrackingStatus
                                ?.orderPlacedDate
                            )}
                          </span>
                          <span className="activity-text">
                            {oneOrderDetails?.orderTrackingStatus?.orderPlaced}
                          </span>
                        </div>
                      </li>
                    ) : null}
                    {oneOrderDetails?.orderTrackingStatus?.orderShipped ? (
                      <li className="feed-item">
                        <div className="feed-item-list">
                          <span
                            className="date"
                            style={{
                              color: "black",
                              fontWeight: "400",
                              fontSize: "0.7rem",
                            }}
                          >
                            {formatDate(
                              oneOrderDetails?.orderTrackingStatus
                                ?.orderShippedDate
                            )}
                          </span>
                          <span className="activity-text">
                            {oneOrderDetails?.orderTrackingStatus?.orderShipped}
                          </span>
                        </div>
                      </li>
                    ) : null}
                    {oneOrderDetails?.orderTrackingStatus?.orderDelivered ? (
                      <li className="feed-item">
                        <div className="feed-item-list">
                          <span
                            className="date"
                            style={{
                              color: "black",
                              fontWeight: "400",
                              fontSize: "0.7rem",
                            }}
                          >
                            {formatDate(
                              oneOrderDetails?.orderTrackingStatus
                                ?.orderDeliveredDate
                            )}
                          </span>
                          <span className="activity-text">
                            {
                              oneOrderDetails?.orderTrackingStatus
                                ?.orderDelivered
                            }
                          </span>
                        </div>
                      </li>
                    ) : null}
                    {oneOrderDetails?.orderTrackingStatus?.orderCancelled ? (
                      <li className="feed-item">
                        <div className="feed-item-list">
                          <span
                            className="date"
                            style={{
                              color: "black",
                              fontWeight: "400",
                              fontSize: "0.7rem",
                            }}
                          >
                            {formatDate(
                              oneOrderDetails?.orderTrackingStatus
                                ?.orderCancelledDate
                            )}
                          </span>
                          <span className="activity-text">
                            {
                              oneOrderDetails?.orderTrackingStatus
                                ?.orderCancelled
                            }
                          </span>
                        </div>
                      </li>
                    ) : null}
                  </ol>
                  <div className="text-center"></div>
                </CardBody>
              </Card>
            </Col>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.7rem",
                  textAlign: "center",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  textAlign: "center",
                },
                "@media (min-width:  970px) ": {
                  fontSize: "0.9rem",
                  textAlign: "center",
                },
              }}
            >
              Order Notes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h6>Add notes:</h6>
            </div>
            <div>
              <input
                className="textArea"
                value={content}
                onChange={e => setContent(e.target.value)}
                placeholder="Please enter your notes here"
              ></input>

              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit", margin: "8px" }}
                variant="contained"
                onClick={() => {
                  addNote()
                }}
              >
                Add
              </Button>
            </div>

            <div style={{ marginTop: "20px" }}>
              {oneOrderDetails?.notes
                ?.slice()
                .reverse()
                ?.map((note, index) => (
                  <>
                    <div key={index}>
                      <div className="dateContainer">
                        <p className="date">{formatDate(note?.date)}</p>
                        <p className="messageBy">by {note?.noteBy}</p>
                        <a
                          href="#"
                          class="delete_note"
                          role="button"
                          onClick={() => deleteNote(index)}
                        >
                          Delete note
                        </a>
                      </div>
                      <div key={index} className="box">
                        <p>{note?.message}</p>
                        {/* <p>{note?.noteBy}</p> */}
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}

export default OrderDetail

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}
