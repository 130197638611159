import PropTypes from "prop-types"
import React, { useState, useRef } from "react"
import RevenueCharts from "./RevenueCharts"
import CancelIcon from "@mui/icons-material/Cancel"
import {
  useOrdersThisYear,
  useOrdersThisMonth,
  useOrdersThisWeek,
  useOrdersTodays,
  useLastBooking,
  useBookingsThisMonth,
  useBookingsThisYear,
  useBookingsThisWeek,
  useBookingsTodays,
} from "common/Hooks/queryCalls"
import { useOrder } from "common/Services/order"
import { formatDate } from "../../common/Services/functions"
import moment from "moment"
import { useFormik } from "formik"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Button } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { useProduct } from "common/Services/product"
import { useNavigate } from "react-router-dom"

// import images
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import { useLastOrder, useCurrentProvider } from "common/Hooks/queryCalls"
import { formatCurrency } from "common/Services/functions"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/material"
import Modal from "react-bootstrap/Modal"
import { saveAs } from "file-saver"

// import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next"
import envConfig from "../../env/env.json"

const shopUrl = envConfig.SHOP_URL.replace(/^(https?:\/\/)/, "")

const Dashboard = props => {
  const navigate = useNavigate()
  const { data, isLoading } = useCurrentProvider()
  const lastOrder = useLastOrder({ providerId: data?._id })
  const lastBooking = useLastBooking({ providerId: data?._id })
  // console.log("data:", lastBooking)

  const [show, setShow] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [currentYear, setCurrentYear] = useState(moment().format("yyyy"))
  const [currentMonth, setCurrentMonth] = useState(moment())
  const startOfDay = moment(startDate).startOf("day").toDate()
  const endOfDay = moment(endDate).endOf("day").toDate()
  const startOfYear = moment(currentYear).startOf("year").toDate()
  const endOfYear = moment(currentYear).endOf("year").toDate()
  const startOfMonth = moment(currentMonth).startOf("month").toDate()
  const endOfMonth = moment(currentMonth).endOf("month").toDate()
  const startOfWeek = moment(currentMonth).startOf("isoweek").toDate()
  const endOfWeek = moment(currentMonth).endOf("isoweek").toDate()
  const startTodays = moment(currentMonth).startOf("day").toDate()
  const endTodays = moment(currentMonth).endOf("day").toDate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isQrGenerated, setIsQrGenerated] = useState(false)
  const [qrCodeLink, setQrCodeLink] = useState("") // Store the QR code link
  const canvasRef = useRef(null)

  const currentProvider = useCurrentProvider()

  const {
    completedOrdersData,
    revenueData,
    revenueIsLoading,
    revenueRefetch,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
  } = useOrder()
  // console.log("revenueData=============", revenueData)
  const {
    providerId,
    categoryData,
    categoryIsLoading,
    categoryRefetch,
    categoryDelete,
  } = useProduct()

  const handleCopyLink = () => {
    const link = `https://${shopUrl}p/${data?.branch?.prefix}`
    navigator.clipboard.writeText(link).then(() => {
      setLinkCopied(true)
      setTimeout(() => setLinkCopied(false), 2000)
    })
  }

  const handleQRButtonClick = () => {
    if (!isQrGenerated) {
      generateQRCode()
    } else {
      downloadQRCode()
    }
  }

  const generateQRCode = () => {
    // Logic to set the link for the QR code
    const linkToGenerate = `https://${shopUrl}p/${data?.branch?.prefix}`
    setQrCodeLink(linkToGenerate)
    setIsQrGenerated(true)
  }

  const downloadQRCode = () => {
    const canvas = canvasRef.current.querySelector("canvas")
    if (canvas) {
      canvas.toBlob(blob => {
        saveAs(blob, "qr-code.png")
      })
    }
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  const handleStartDateChange = event => {
    setDateFrom(event.target.value)
  }

  // console.log("dateFrom======", dateFrom)

  const handleEndDateChange = event => {
    setDateTo(event.target.value)
  }
  // console.log("dateTo======", dateTo)
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  const thisTodays = useOrdersTodays({
    startTodays,
    endTodays,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalAmountTodays = () => {
    const sum = thisTodays?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  const thisWeek = useOrdersThisWeek({
    startOfWeek,
    endOfWeek,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalAmountThisWeek = () => {
    const sum = thisWeek?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  const thisYear = useOrdersThisYear({
    startOfYear,
    endOfYear,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalAmountThisYear = () => {
    const sum = thisYear?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  const thisMonth = useOrdersThisMonth({
    startOfMonth,
    endOfMonth,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalAmountThisMonth = () => {
    const sum = thisMonth?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  //booking api

  const thisTodaysBooking = useBookingsTodays({
    startTodays,
    endTodays,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalBookingAmountTodays = () => {
    const sum = thisTodaysBooking?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  const thisWeekBooking = useBookingsThisWeek({
    startOfWeek,
    endOfWeek,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalBookingAmountThisWeek = () => {
    const sum = thisWeekBooking?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  const thisYearBooking = useBookingsThisYear({
    startOfYear,
    endOfYear,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalBookingAmountThisYear = () => {
    const sum = thisYearBooking?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  const thisMonthBooking = useBookingsThisMonth({
    startOfMonth,
    endOfMonth,
    providerId: currentProvider?.data?._id,
    branchId: selectedBranch,
  })

  const getTotalBookingAmountThisMonth = () => {
    const sum = thisMonthBooking?.data?.reduce(
      (acc, obj) => acc + obj?.payments?.amountPayable,
      0
    )
    return sum
  }

  document.title = "Dashboard | Merche"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <div className="cancel" onClick={handleClose}>
                <CancelIcon />
              </div>

              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  {revenueData?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Items Available
                    </caption>
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Orders
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Revenue
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {revenueData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(item => {
                        if (item.category && item.category.length > 0) {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={item._id}
                            >
                              <TableCell
                                align="center"
                                sx={tableBodyStyle}
                                style={{ fontFamily: "outfit" }}
                              >
                                {item?.category[0]?.name?.en}
                              </TableCell>

                              <TableCell align="center" sx={tableBodyStyle}>
                                {item?.count}
                              </TableCell>
                              <TableCell align="center" sx={tableBodyStyle}>
                                {item?.totalSaleAmount}
                              </TableCell>
                            </TableRow>
                          )
                        } else {
                          return null
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={tableFooterStyle}
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={categoryData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Modal>
          <div className="page-title-box">
            <Row className="align-items-center" style={{ marginTop: "-80px" }}>
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Merche Dashboard
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            {data?.type === "booking-services" ? (
              <>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#333547" }}
                    onClick={() => navigate(`/revenue-booking/today`)}
                  >
                    <CardBody>
                      {thisTodaysBooking?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6 className=" text-uppercase mt-0 text-white-50">
                              Todays Revenue
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(
                                getTotalBookingAmountTodays() || 0
                              )}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {thisTodaysBooking?.data?.length || 0}
                              </h6>
                            </div>

                            <p className="text-white-50 mb-0 mt-1">
                              Total Bookings
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat  text-white"
                    style={{ backgroundColor: "#333547" }}
                    onClick={() => navigate(`/revenue-booking/this-week`)}
                  >
                    <CardBody>
                      {thisWeekBooking?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6 className=" text-uppercase mt-0 text-white-50">
                              This Week
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(
                                getTotalBookingAmountThisWeek() || 0
                              )}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {thisWeekBooking?.data?.length || 0}
                              </h6>
                            </div>

                            <p className="text-white-50 mb-0 mt-1">
                              Total Bookings
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#333547" }}
                    onClick={() => navigate(`/revenue-booking/this-month`)}
                  >
                    <CardBody>
                      {thisMonthBooking?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6
                              className="text-uppercase mt-0 text-white-50"
                              style={{
                                marginInlineStart: 5,
                                marginInlineEnd: 10,
                              }}
                            >
                              This Month
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(
                                getTotalBookingAmountThisMonth() || 0
                              )}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {" "}
                                {thisMonthBooking?.data?.length || 0}
                              </h6>
                            </div>
                            <p className="text-white-50 mb-0 mt-1">
                              Total Bookings
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#333547" }}
                    onClick={() => navigate(`/revenue-booking/this-year`)}
                  >
                    <CardBody>
                      {thisYearBooking?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6 className="text-uppercase mt-0 text-white-50">
                              This year
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(
                                getTotalBookingAmountThisYear() || 0
                              )}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {" "}
                                {thisYearBooking?.data?.length || 0}
                              </h6>
                            </div>

                            <p className="text-white-50 mb-0 mt-1">
                              Total Bookings
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : data?.type === "e-commerce" ? (
              <>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#333547" }}
                  >
                    <CardBody>
                      {thisTodays?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6 className=" text-uppercase mt-0 text-white-50">
                              Todays Revenue
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(getTotalAmountTodays() || 0)}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {thisTodays?.data?.length || 0}
                              </h6>
                            </div>

                            <p className="text-white-50 mb-0 mt-1">
                              Total Orders
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat  text-white"
                    style={{ backgroundColor: "#333547" }}
                  >
                    <CardBody>
                      {thisWeek?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6 className=" text-uppercase mt-0 text-white-50">
                              This Week
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(getTotalAmountThisWeek() || 0)}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {thisWeek?.data?.length || 0}
                              </h6>
                            </div>

                            <p className="text-white-50 mb-0 mt-1">
                              Total Orders
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#333547" }}
                  >
                    <CardBody>
                      {thisMonth?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6
                              className="text-uppercase mt-0 text-white-50"
                              style={{
                                marginInlineStart: 5,
                                marginInlineEnd: 10,
                              }}
                            >
                              This Month
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(getTotalAmountThisMonth() || 0)}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {" "}
                                {thisMonth?.data?.length || 0}
                              </h6>
                            </div>
                            <p className="text-white-50 mb-0 mt-1">
                              Total Orders
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{ backgroundColor: "#333547" }}
                  >
                    <CardBody>
                      {thisYear?.isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      ) : (
                        <>
                          <div className="mb-4">
                            <div className="float-start mini-stat-img me-4">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h6 className="text-uppercase mt-0 text-white-50">
                              This year
                            </h6>
                            <h4 className="fw-medium font-size-24">
                              {formatCurrency(getTotalAmountThisYear() || 0)}{" "}
                            </h4>
                          </div>
                          <div className="pt-2">
                            <div className="float-end">
                              <h6 style={{ color: "white" }}>
                                {" "}
                                {thisYear?.data?.length || 0}
                              </h6>
                            </div>

                            <p className="text-white-50 mb-0 mt-1">
                              Total Orders
                            </p>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>

          <Row>
            {data?.type === "booking-services" ? (
              <>
                <Col xl={6} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardBody>
                      <div
                        className="mb-1"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 className="" style={{ color: "black" }}>
                          Share scheduler link
                        </h6>
                        {isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <CircularProgress sx={{ color: "#626ed4" }} />
                          </Box>
                        ) : (
                          <>
                            <p className="" style={{ color: "grey" }}>
                              {" "}
                              Click on the link provided to place your bookings
                              with ease and convenience.
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={handleCopyLink}
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.5rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                }}
                              >
                                {linkCopied
                                  ? "Copied!"
                                  : "Share scheduler Link"}
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() =>
                                  window.open(
                                    `https://${shopUrl}p/${data?.branch?.prefix}`,
                                    "_blank"
                                  )
                                }
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                    marginLeft: 2,
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                      marginLeft: 2,
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                    marginLeft: 2,
                                  },
                                }}
                              >
                                Visit scheduler
                              </Button>
                              <div>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={handleQRButtonClick} // Correctly reference the function
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.4rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                      marginLeft: 2,
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.6rem",
                                        backgroundColor: "#333547",
                                        fontFamily: "outfit",
                                        marginLeft: 2,
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                      marginLeft: 2,
                                    },
                                  }}
                                >
                                  {isQrGenerated
                                    ? "Download QR Code"
                                    : "Generate QR Code"}
                                </Button>

                                {/* <div style={{ marginTop: 30 }} ref={canvasRef}>
                                  {qrCodeLink && (
                                    <QRCodeCanvas
                                      value={qrCodeLink}
                                      size={200}
                                    />
                                  )}
                                </div> */}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardBody>
                      <div
                        className="mb-1"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 className="" style={{ color: "black" }}>
                          Last Booking
                        </h6>

                        {lastBooking?.isLoading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <CircularProgress sx={{ color: "#626ed4" }} />
                          </Box>
                        ) : lastBooking?.data === undefined ? (
                          <h6 style={{ color: "grey" }}>
                            There is no booking right now
                          </h6>
                        ) : (
                          <>
                            <p
                              className=""
                              style={{ color: "grey", marginBottom: "2px" }}
                            >
                              <span>
                                {" "}
                                {`Booking No: ${lastBooking?.data?.refNumber}`}
                              </span>
                            </p>
                            {lastBooking?.data?.serviceMemberData?.length >
                            0 ? (
                              <p
                                className=""
                                style={{ color: "grey", marginBottom: "2px" }}
                              >
                                <span>
                                  {" "}
                                  {`Assigned To: ${lastBooking?.data?.serviceMemberData[0]?.name} `}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                            <p className="" style={{ color: "grey" }}>
                              <span>
                                {" "}
                                {`Total Amount: ${formatCurrency(
                                  lastBooking?.data?.payments?.amountPayable
                                )}`}
                              </span>
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "space-around",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() =>
                                  navigate(
                                    `/booking-details/${lastBooking?.data?._id}`
                                  )
                                }
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                }}
                              >
                                Go To Last Booking
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={3} md={6}>
                  <Card
                    className="mini-stat text-white"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardBody>
                      <div
                        className="mb-2"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h6 className="" style={{ color: "black" }}>
                          Create Booking
                        </h6>

                        <>
                          <p className="" style={{ color: "grey" }}>
                            {" "}
                            Create a New Booking with Ease! 🛍️ Click on the
                            'Create New Booking' button in the dashboard
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-around",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => navigate(`/create-booking`)}
                              color="primary"
                              className="btn btn-primary waves-effect waves-light"
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.4rem",
                                  backgroundColor: "#333547",
                                  fontFamily: "outfit",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                "@media (min-width: 970px)": {
                                  fontSize: "0.6rem",
                                  backgroundColor: "#333547",
                                  fontFamily: "outfit",
                                },
                              }}
                            >
                              Create New Booking
                            </Button>
                          </div>
                        </>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : data?.type === "e-commerce" ? (
              <>
                <Col xl={4} md={5}>
                  <Col style={{ display: "flex" }}>
                    <Card
                      className="mini-stat text-white"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardBody>
                        <div
                          className="mb-1"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h6 className="" style={{ color: "black" }}>
                            Share store link
                          </h6>
                          {isLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <CircularProgress sx={{ color: "#626ed4" }} />
                            </Box>
                          ) : (
                            <>
                              <p className="" style={{ color: "grey" }}>
                                {" "}
                                Click on the link provided to place your orders
                                with ease and convenience.
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={handleCopyLink}
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.4rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.5rem",
                                        backgroundColor: "#333547",
                                        fontFamily: "outfit",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                  }}
                                >
                                  {linkCopied ? "Copied!" : "Share Store Link"}
                                </Button>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() =>
                                    window.open(
                                      `https://${shopUrl}p/${data?.branch?.prefix}`,
                                      "_blank"
                                    )
                                  }
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.4rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                      marginLeft: 2,
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.6rem",
                                        backgroundColor: "#333547",
                                        fontFamily: "outfit",
                                        marginLeft: 2,
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                      marginLeft: 2,
                                    },
                                  }}
                                >
                                  Visit Store
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col style={{ display: "flex" }}>
                    <Card
                      className="mini-stat text-white"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardBody>
                        <div
                          className="mb-1"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h6 className="" style={{ color: "black" }}>
                            Last Order
                          </h6>

                          {lastOrder?.isLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <CircularProgress sx={{ color: "#626ed4" }} />
                            </Box>
                          ) : lastOrder?.data === undefined ? (
                            <h6 style={{ color: "grey" }}>
                              There is no order right now
                            </h6>
                          ) : (
                            <>
                              <p
                                className=""
                                style={{ color: "grey", marginBottom: "2px" }}
                              >
                                <span>
                                  {" "}
                                  {`Order No: ${lastOrder?.data?.refNumber}`}
                                </span>
                              </p>
                              <p
                                className=""
                                style={{ color: "grey", marginBottom: "2px" }}
                              >
                                <span>
                                  {" "}
                                  {`Customer: ${lastOrder?.data?.customer?.firstName} ${lastOrder?.data?.customer?.lastName}`}
                                </span>
                              </p>
                              <p className="" style={{ color: "grey" }}>
                                <span>
                                  {" "}
                                  {`Total Amount: ${formatCurrency(
                                    lastOrder?.data?.amount
                                  )}`}
                                </span>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // justifyContent: "space-around",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `/${lastOrder?.data?._id}/order-details`
                                    )
                                  }
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light"
                                  sx={{
                                    "@media (max-width: 600px)": {
                                      fontSize: "0.4rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                    "@media (min-width: 601px) and (max-width: 960px)":
                                      {
                                        fontSize: "0.6rem",
                                        backgroundColor: "#333547",
                                        fontFamily: "outfit",
                                      },
                                    "@media (min-width: 970px)": {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                  }}
                                >
                                  Go To Last Order
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col style={{ display: "flex" }}>
                    <Card
                      className="mini-stat text-white"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardBody>
                        <div
                          className="mb-2"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h6 className="" style={{ color: "black" }}>
                            Create Order
                          </h6>

                          <>
                            <p className="" style={{ color: "grey" }}>
                              {" "}
                              Create a New Order with Ease! 🛍️ Click on the
                              'Create New Order' button in the dashboard
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // justifyContent: "space-around",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => navigate(`/create-order`)}
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.4rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                      fontFamily: "outfit",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                    fontFamily: "outfit",
                                  },
                                }}
                              >
                                Create New Order
                              </Button>
                            </div>
                          </>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  {/* <Col xl={3} md={6} style={{ display: "flex" }}>
    <Card
      className="mini-stat text-white"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardBody>
        <div
          className="mb-4"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h6 className="" style={{ color: "black" }}>
            Create Customer
          </h6>

          <>
            <p className="" style={{ color: "grey" }}>
              {" "}
              Onboard a New Customer and Create an Order Ready to get
              started? Click 'Create Order' to seamlessly generate a
              new customer account and craft their order
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate(`/create-order`)}
                color="primary"
                className="btn btn-primary waves-effect waves-light"
                style={{
                  fontFamily: "outfit",
                  // marginBottom: "10px",
                  // minHeight: "40px",
                }}
              >
                Create New Customer
              </Button>
            </div>
          </>
        </div>
      </CardBody>
    </Card>
  </Col> */}
                </Col>
                <Col xl={8} md={7}>
                  <Card>
                    <CardBody>
                      <div className="date-input-div">
                        <label style={{ fontFamily: "outfit" }}>
                          Start Date:
                          <input
                            type="date"
                            value={dateFrom}
                            onChange={handleStartDateChange}
                            className="date-input"
                          />
                        </label>
                        <br />
                        <label style={{ fontFamily: "outfit" }}>
                          End Date:
                          <input
                            type="date"
                            value={dateTo}
                            onChange={handleEndDateChange}
                            className="date-input"
                          />
                        </label>{" "}
                      </div>

                      <div>
                        <Button
                          variant="contained"
                          onClick={handleShow}
                          color="primary"
                          className="btn btn-primary waves-effect waves-light"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.4rem",
                              backgroundColor: "#333547",
                              fontFamily: "outfit",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.6rem",
                                backgroundColor: "#333547",
                                fontFamily: "outfit",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#333547",
                              fontFamily: "outfit",
                            },
                          }}
                        >
                          View breakdown
                        </Button>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <RevenueCharts revenueData={revenueData} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
          {/* {qrCodeLink ? (
            <Row>
              <Col xl={3}>
                <Card>
                  <CardBody> */}
          {/* Button to Generate QR Code */}
          {/* <Button
            variant="contained"
            color="primary"
            className="btn btn-primary waves-effect waves-light"
            onClick={handleQRButtonClick} // Correctly reference the function
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.4rem",
                backgroundColor: "#333547",
                fontFamily: "outfit",
                marginLeft: 2,
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.6rem",
                backgroundColor: "#333547",
                fontFamily: "outfit",
                marginLeft: 2,
              },
              "@media (min-width: 970px)": {
                fontSize: "0.6rem",
                backgroundColor: "#333547",
                fontFamily: "outfit",
                marginLeft: 2,
              },
            }}
          >
            {isQrGenerated ? "Download QR Code" : "Generate QR Code"}
          </Button> */}

          {/* <div ref={canvasRef}>
                      {qrCodeLink && (
                        <QRCodeCanvas value={qrCodeLink} size={200} />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null} */}

          {/* work in progress
          <Row>
            <Col xl={3}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Sales Report</h4>
                  <div className="cleafix">
                    <p className="float-start">
                      <i className="mdi mdi-calendar me-1 text-primary"></i> Jan
                      01 - Jan 31
                    </p>
                    <h5 className="font-18 text-end">$4230</h5>
                  </div>
                  <div id="ct-donut" className="ct-chart wid pt-4">
                    <Salesdonut />
                  </div>
                  <div className="mt-4">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge bg-primary">Desk</span>
                          </td>
                          <td>Desktop</td>
                          <td className="text-end">54.5%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-success">Mob</span>
                          </td>
                          <td>Mobile</td>
                          <td className="text-end">28.0%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-warning">Tab</span>
                          </td>
                          <td>Tablets</td>
                          <td className="text-end">17.5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Activity</h4>
                  <ol className="activity-feed">
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 22</span>
                        <span className="activity-text">
                          Responded to need “Volunteer Activities”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 20</span>
                        <span className="activity-text">
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui deleniti atque...
                          <Link to="#" className="text-success">
                            Read more
                          </Link>
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 19</span>
                        <span className="activity-text">
                          Joined the group “Boardsmanship Forum”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 17</span>
                        <span className="activity-text">
                          Responded to need “In-Kind Opportunity”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 16</span>
                        <span className="activity-text">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          rem.
                        </span>
                      </div>
                    </li>
                  </ol>
                  <div className="text-center">
                    <Link to="#" className="btn btn-primary">
                      Load More
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={5}>
              <Row>
                <Col md={6}>
                  <Card className="text-center">
                    <CardBody>
                      <div className="py-4">
                        <i className="ion ion-ios-checkmark-circle-outline display-4 text-success"></i>

                        <h5 className="text-primary mt-4">Order Successful</h5>
                        <p className="text-muted">
                          Thanks you so much for your order.
                        </p>
                        <div className="mt-4">
                          <Link to="#" className="btn btn-primary btn-sm">
                            Chack Status
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="bg-primary">
                    <CardBody>
                      <div className="text-center text-white py-4">
                        <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                          Top Product Sale
                        </h5>
                        <h1>1452</h1>
                        <p className="font-size-14 pt-1">Computer</p>
                        <p className="text-white-50 mb-0">
                          At solmen va esser necessi far uniform myth...{" "}
                          <Link to="#" className="text-white">
                            View more
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Client Reviews</h4>
                      <p className="text-muted mb-3 pb-4">
                        " Everyone realizes why a new common language would be
                        desirable one could refuse to pay expensive translators
                        it would be necessary. "
                      </p>
                      <div className="float-end mt-2">
                        <Link to="#" className="text-primary">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>
                      <h6 className="mb-0">
                        {" "}
                        <img
                          src={user3}
                          alt=""
                          className="avatar-sm rounded-circle me-2"
                        />{" "}
                        James Athey
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row> */}

          {/* work in progress */}
          {/* <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Latest Transaction</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">(#) Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>15/1/2018</td>
                          <td>$94</td>
                          <td>
                            <span className="badge bg-success">Delivered</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14257</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Brent Shipley
                            </div>
                          </td>
                          <td>16/1/2019</td>
                          <td>$112</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14258</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Robert Sitton
                            </div>
                          </td>
                          <td>17/1/2019</td>
                          <td>$116</td>
                          <td>
                            <span className="badge bg-success">Delivered</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14259</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alberto Jackson
                            </div>
                          </td>
                          <td>18/1/2019</td>
                          <td>$109</td>
                          <td>
                            <span className="badge bg-danger">Cancel</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14260</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              David Sanchez
                            </div>
                          </td>
                          <td>19/1/2019</td>
                          <td>$120</td>
                          <td>
                            <span className="badge bg-success">Delivered</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14261</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>15/1/2018</td>
                          <td>$94</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Chat</h4>
                  <div className="chat-conversation">
                    <SimpleBar style={{ height: "365px" }}>
                      <ul
                        className="conversation-list"
                        data-simplebar
                        style={{ maxHeight: "367px" }}
                      >
                        <li className="clearfix">
                          <div className="chat-avatar">
                            <img
                              src={user2}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:00</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">John Deo</span>
                              <p>Hello!</p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix odd">
                          <div className="chat-avatar">
                            <img
                              src={user3}
                              className="avatar-xs rounded-circle"
                              alt="Female"
                            />
                            <span className="time">10:01</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">Smith</span>
                              <p>
                                Hi, How are you? What about our next meeting?
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix">
                          <div className="chat-avatar">
                            <img
                              src={user2}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:04</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">John Deo</span>
                              <p>Yeah everything is fine</p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix odd">
                          <div className="chat-avatar">
                            <img
                              src={user3}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:05</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">Smith</span>
                              <p>Wow that's great</p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix odd">
                          <div className="chat-avatar">
                            <img
                              src={user3}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:08</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name mb-2">Smith</span>

                              <img
                                src={smimg1}
                                alt=""
                                height="48"
                                className="rounded me-2"
                              />
                              <img
                                src={smimg2}
                                alt=""
                                height="48"
                                className="rounded"
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </SimpleBar>

                    <Row className="mt-3 pt-1">
                      <Col md="9" className="chat-inputbar col-8">
                        <Input
                          type="text"
                          className="chat-input"
                          placeholder="Enter your text"
                        />
                      </Col>
                      <Col md="3" className="chat-send col-4">
                        <div className="d-grid">
                          <Button
                            type="submit"
                            color="success"
                            className="btn-block"
                          >
                            Send
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
const buttonStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.675rem",
    backgroundColor: "#333547",
    color: "white",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.775rem",
    backgroundColor: "#333547",
    color: "white",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.775rem",
    backgroundColor: "#333547",
    color: "white",
  },
}
