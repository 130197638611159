import React, { useState } from "react"
import { Bar, Line } from "react-chartjs-2"
import { Input } from "reactstrap"

const RevenueCharts = ({ revenueData }) => {
  const [selectedChart, setSelectedChart] = useState("line")
  if (!revenueData || revenueData.length === 0) {
    return <p>No revenue data available</p>
  }
  const filteredData = revenueData.filter(
    item => item.category && item.category.length > 0
  )
  const categories = filteredData.map(
    item => item.category[0]?.name?.en || "Unknown"
  )
  const revenueAmounts = filteredData.map(item => item.totalSaleAmount || 0)

  const barChartData = {
    labels: categories,
    datasets: [
      {
        label: "Revenue by Category",
        data: revenueAmounts,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        // backgroundColor:'rgba(255, 99, 132, 0.2)',
        borderColor: "rgba(75, 192, 192, 1)",
        // borderColor:'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  }

  const lineChartData = {
    labels: categories,
    datasets: [
      {
        label: "Revenue by Category (Line Chart)",
        data: revenueAmounts,
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        // borderColor: 'rgba(54, 162, 235, 1)',
      },
    ],
  }

  return (
    <div>
      {/* Step 2: Create a dropdown component */}
      <div>
        <h6>Select Chart Type:</h6>
        <Input
          type="select"
          onChange={e => setSelectedChart(e.target.value)}
          value={selectedChart}
        >
          <option value="line">Line Chart</option>
          <option value="bar">Bar Chart</option>
        </Input>
      </div>

      <div>
        {selectedChart === "line" ? (
          <div>
            {/* <h3>Line Chart</h3> */}
            <Line data={lineChartData} />
          </div>
        ) : (
          <div>
            {/* <h3>Bar Chart</h3> */}
            <Bar data={barChartData} />
          </div>
        )}
      </div>
    </div>
  )
}

export default RevenueCharts
