import * as Realm from "realm-web"
import envConfig from "../../env/env.json"
export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID })
export const gApp = new Realm.App({ id: envConfig.MONGODB_APP_ID_GENERAL })
export const {
  BSON: { ObjectId },
} = Realm

export const getDBInstance = () => {
  if (!app || !app.currentUser) {
    return
  }
  return app.currentUser.mongoClient("mongodb-atlas").db(envConfig.MONGODB_DB)
}

export const generalLogin = async () => {
  const gUser = await gApp.logIn(
    Realm.Credentials.apiKey(envConfig.MONGODB_APP_TOKEN_GENERAL)
  )
  return gUser
}
//Login
export const getUser = async email => {
  if (!app || !app.currentUser) {
    return
  }
  return await getDBInstance(app).collection("User").findOne({ email: email })
}

export const getAllCategory = async ({
  providerId,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  // const queryOptions = {
  //   // Sort the data by a field in descending order to get the latest data at the top
  //   sort: { _id: -1 },
  //   // projection: 3
  // }

  // console.log("the options", options)

  const result = await getDBInstance(app)
    .collection("categories")
    // .find({ providerId }, queryOptions)
    .aggregate([
      {
        $match: { providerId },
      },
      { $sort: options.sort },
      { $skip: options.skip },
      { $limit: options.limit },
    ])

  // console.log("the result", result)

  return result
}

export const getFetchAllCategory = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
    // projection: 3
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .find({ providerId }, queryOptions)

  // console.log("the result", result)

  return result
}

export const getAllCategoryCount = async ({ providerId, options = {} }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .count({ providerId })

  // console.log("the count", result)

  return result
}

export const DeleteCategory = async ({ itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .deleteOne({ _id: itemId })

  return result
}

export const AddOneCategory = async ({ data, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!data || !providerId) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const metadata = {
    createdBy: null,
    isActive: true,
    createdAt: new Date(),
    modifiedBy: null,
    modifiedAt: null,
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .insertOne({
      metadata: metadata,
      name: {
        // ar: data.arabicName,
        en: data.englishName,
      },
      showInApp: true,
      mainImage: null,
      providerId,
    })
  return result
}

export const UpdateOneCategory = async ({ item, data, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !data || !providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.isActive": true,
          "metadata.modifiedAt": new Date(),
          name: {
            en: data.englishName,
          },
          showInApp: true,
          mainImage: null,
          providerId,
        },
      }
    )

  return result
}

export const registerDetails = async ({
  email,
  userName,
  phoneNumber,
  description,
  type,
}) => {
  const data = {
    email,
    englishName: userName,
    number: phoneNumber,
    englishBio: description,
    type,
  }
  if (!data) {
    return
  }

  const credentials = Realm.Credentials.anonymous()
  const test = await app.logIn(credentials)

  if (test) {
    const result = await app.currentUser.callFunction("registerProvider", {
      data,
    })

    if (app.currentUser) {
      app.currentUser.logOut()
    }
    return result
  } else {
  }
}

export const updateBranch = async ({
  providerId,
  itemId,
  data,
  inStockStatus,
  // geoCode,
  // cityId,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !itemId || !data) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  // if (typeof cityId === "string") {
  //   cityId = new ObjectId(cityId);
  // }
  // console.log("geoCode::", geoCode);

  const result = await getDBInstance(app)
    .collection("branches")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          name: {
            ar: data.englishName,
            en: data.englishName,
          },
          email: data.email,
          number: data.number,
          username: data.username,
          prefix: data.prefix.toUpperCase(),
          address: data.branchAddress,
          deliveryCharge: data.deliveryCharge,
          inStockStatus: inStockStatus,
          // city: cityId,
          // geo: {
          //   coordinates: [geoCode.lng, geoCode.lat],
          //   type: "Point",
          // },
        },
      }
    )

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId: itemId },
    })
    return { result, appServiceFun }
  }
}

export const updateStoreLocation = async ({
  providerId,
  itemId,
  address,
  marker,
  inStockStatus,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !itemId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  // console.log("geoCode::", marker)

  const result = await getDBInstance(app)
    .collection("branches")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          storeAddress: address,
          geo: {
            coordinates: [marker.lng, marker.lat],
            type: "Point",
          },
          inStockStatus: inStockStatus,
        },
      }
    )

  return result
}

export const getBranchModifiers = async ({ branchId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!branchId) {
    return
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }

  const result = await getDBInstance(app)
    .collection("branchmodifiers")
    .aggregate([
      {
        $match: {
          branchId: branchId,
        },
      },
      {
        $lookup: {
          from: "modifiers",
          localField: "modifierId",
          foreignField: "_id",
          as: "getBranchModifiers",
        },
      },
      {
        $unwind: "$getBranchModifiers",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const getBranchProducts = async ({
  branchId,
  status,
  stock,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!branchId) {
    return
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }

  const query = {
    $and: [{ branchId: branchId }],
  }

  if (status != "Status") {
    if (status === "Active") {
      query.$and.push({
        "metadata.isActive": true,
      })
    } else {
      query.$and.push({
        "metadata.isActive": false,
      })
    }
  }

  if (stock != "Stock") {
    if (stock === "Active") {
      query.$and.push({
        inStock: true,
      })
    } else {
      query.$and.push({
        inStock: false,
      })
    }
  }

  // console.log("branchProduct::", branchId);
  //5f68380a14721c6445ed45ea
  const result = await getDBInstance(app)
    .collection("branchmenus")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "menus",
          localField: "menuId",
          foreignField: "_id",
          as: "getBranchProducts",
        },
      },
      {
        $unwind: "$getBranchProducts",
      },
      { $sort: options.sort },
      { $skip: options.skip },
      { $limit: options.limit },
    ])

  return result
}

export const getBranchProductsCount = async ({ branchId, status, stock }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!branchId) {
    return
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }

  const query = {
    $and: [{ branchId: branchId }],
  }

  if (status != "Status") {
    if (status === "Active") {
      query.$and.push({
        "metadata.isActive": true,
      })
    } else {
      query.$and.push({
        "metadata.isActive": false,
      })
    }
  }

  if (stock != "Stock") {
    if (stock === "Active") {
      query.$and.push({
        inStock: true,
      })
    } else {
      query.$and.push({
        inStock: false,
      })
    }
  }

  const result = await getDBInstance(app).collection("branchmenus").count(query)

  return result
}

export const UpdateMenuApprove = async ({ item }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("menus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          isApproved: true,
        },
      }
    )

  return result
}

export const UpdateAddonApprove = async ({ item }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!item) {
    return
  }
  //console.log("item::", item);

  const result = await getDBInstance(app)
    .collection("modifiers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          isApproved: true,
        },
      }
    )

  return result
}

//Revenue
export const getProvidersSearch = async () => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .aggregate([
      {
        $match: {
          isApproved: true,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])
  return result
}

export const cancelOrder = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  const gUser = await generalLogin()

  return await gUser?.callFunction("orders", {
    functionName: "cancelOrder",
    params: { id },
  })
}

export const acceptOrder = async ({ id }) => {
  // console.log("db service id", id)
  if (!app || !app.currentUser) {
    // alert("!app")
    return
  }
  const gUser = await generalLogin()
  // console.log("gUser", gUser)
  return await gUser?.callFunction("orders", {
    functionName: "acceptOrder",
    params: { id },
  })
}

export const acceptOrderChat = async ({ id }) => {
  // console.log("db service id", id)
  if (!app || !app.currentUser) {
    // alert("!app")
    return
  }
  const gUser = await generalLogin()
  // console.log("gUser", gUser)
  return await gUser?.callFunction("orders", {
    functionName: "acceptOrderPaymentPaid",
    params: { id },
  })
}

export const OrderStatusUpdate = async ({ itemId, orderStatus }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!itemId || !orderStatus) {
    return
  }

  const gUser = await generalLogin()
  // console.log("gUser", gUser)
  // console.log("orderStatus", orderStatus)
  const params = {
    _id: itemId,
    orderStatus: orderStatus,
  }

  const result = await gUser?.callFunction("orders", {
    functionName: "orderStatusChange",
    params: params,
  })

  return result

  return result
}

export const getOngoingOrders = async ({
  providerId,
  status,
  paymentStatus,
  startDate,
  endDate,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const query = {
    $and: [{ providerId: providerId }],
  }
  // console.log("status:", status)
  if (status === "all") {
    query.$and.push({
      status: { $nin: ["completed", "refunded"] },
    })
  }

  if (status === "pending") {
    query.$and.push({
      status: status,
    })
  }

  if (status === "accepted") {
    query.$and.push({
      status: status,
    })
  }
  if (status === "ready") {
    query.$and.push({
      status: status,
    })
  }
  if (status === "cancelled") {
    query.$and.push({
      status: status,
    })
  }
  if (paymentStatus === "pending") {
    query.$and.push({
      paymentStatus: "pending",
    })
  }
  if (paymentStatus === "paid") {
    query.$and.push({
      paymentStatus: { $in: ["Paid", "paid"] },
    })
  }
  if (startDate) {
    query.$and.push({
      date: { $gt: startDate },
    })
  }
  if (endDate) {
    query.$and.push({
      date: { $lt: endDate },
    })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}
// export const getCompletedOrders = async ({
//   providerId,
//   completedStartDate,
//   completedEndDate,
// }) => {
//   if (!app || !app.currentUser) {
//     return
//   }

//   if (!providerId) {
//     return
//   }
//   if (typeof providerId === "string") {
//     providerId = new ObjectId(providerId)
//   }

//   const result = await getDBInstance(app)
//     .collection("orders")
//     .aggregate([
//       {
//         $match: {
//           providerId: providerId,
//           status: { $in: ["completed"] },
//           paymentStatus: { $in: ["paid", "Paid", "completed"] },
//           date: { $gt: completedStartDate },
//           date: { $lt: completedEndDate },
//         },
//       },
//       {
//         $lookup: {
//           from: "customers",
//           localField: "customerId",
//           foreignField: "_id",
//           as: "customer",
//         },
//       },
//       {
//         $unwind: "$customer",
//       },
//       {
//         $sort: {
//           _id: -1,
//         },
//       },
//     ])

//   return result
// }
export const getCompletedOrders = async ({
  providerId,
  completedStartDate,
  completedEndDate,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      { providerId: providerId },
      { status: { $in: ["completed"] } },
      { paymentStatus: { $in: ["paid", "Paid", "completed"] } },
    ],
  }

  if (completedStartDate) {
    query.$and.push({
      date: { $gt: completedStartDate },
    })
  }
  if (completedEndDate) {
    query.$and.push({
      date: { $lt: completedEndDate },
    })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])

  return result
}

export const getCurrentProvider = async ({ providerId }) => {
  // console.log("got value", providerId);
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .aggregate([
      {
        $match: {
          _id: providerId,
        },
      },
      {
        $lookup: {
          from: "branches",
          localField: "_id",
          foreignField: "providerId",
          as: "branch",
        },
      },

      {
        $unwind: "$branch",
      },
    ])

  return result[0]

  // return await getDBInstance(app)
  //   .collection("providers")
  //   .findOne({ _id: providerId })
}

export const getSubCategories = async ({
  providerId,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  // console.log("sub options:", options)

  const result = await getDBInstance(app)
    .collection("subcategories")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "categories",
          localField: "categoryId",
          foreignField: "_id",
          as: "getCategories",
        },
      },
      {
        $unwind: "$getCategories",
      },
      { $sort: options.sort },
      { $skip: options.skip },
      { $limit: options.limit },
    ])
  // console.log("sub result:", result)
  return result
}

export const getSubCategoriesCount = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .count({ providerId })

  return result
}
export const addSubCategory = async ({ providerId, data, categoryId }) => {
  // console.log(" providerId, data, categoryId", providerId, data, categoryId)
  if (!app || !app.currentUser) {
    // console.log("!app || !app.currentUser")
    return
  }
  // if (!providerId || !data) {
  //   // console.log("!providerId || !data")
  //   return
  // }

  //add later this now categoryid not required
  if (!providerId || !data || !categoryId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  // console.log("providerId:", typeof providerId)

  if (typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId)
  }

  const metadata = {
    // createdBy: entityId,
    //modifiedAt:'',
    //modifiedBy:'',
    isActive: true,
    createdAt: new Date(),
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .insertOne({
      metadata: metadata,
      name: {
        // ar: data.arabicName,
        en: data.englishName,
      },
      providerId: providerId,
      isApproved: true,
      categoryId: categoryId,
    })

  return result
}
export const updateSubCategory = async ({ item, data, categoryId }) => {
  // console.log('edit dbservice ',item, data, categoryId )
  // console.log("item.id", item._id)
  if (!app || !app.currentUser) {
    return
  }
  if (!item || !data) {
    return
  }

  // if (!item || !data || !categoryId) {
  //   return;
  // }
  // console.log('typeof categoryId',typeof categoryId)
  // if (typeof categoryId === "string") {
  //   console.log('typeof categoryId === "string"')
  //   categoryId = new ObjectId(categoryId);
  // }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          name: {
            // ar: data.arabicName,
            en: data.englishName,
          },
          categoryId: categoryId,
        },
      }
    )

  return result
}
export const deleteBranch = async ({ providerId, branchId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !branchId) {
    return
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("branches")
    .deleteOne({ _id: branchId })

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId },
    })
    return { result, appServiceFun }
  }
}
export const deleteSubCategory = async ({ subCategoryId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!subCategoryId) {
    return
  }
  if (typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId)
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .deleteOne({ _id: subCategoryId })

  return result
}
export const getCategorySetting = async ({ categoryId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!categoryId) {
    return
  }
  if (typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId)
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .aggregate([
      {
        $match: {
          _id: categoryId,
          // "metadata.isActive": true,
          // type: { $in: ["home-business", "food"] }
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])
  return result[0]
}
export const getCategorySearch = async ({ search, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .aggregate([
      {
        $match: {
          $or: [
            { "name.en": { $regex: `^${search}`, $options: "i" } },
            { "name.ar": { $regex: `^${search}`, $options: "i" } },
          ],
          "metadata.isActive": true,
          providerId,
        },
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])
  return result
}

export const getModifiers = async ({ providerId }) => {
  // console.log("providerIdkkkk", providerId)
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    .find({ providerId: providerId }, queryOptions)
  // console.log("result getModifiers", result)
  return result
}
export const addAddons = async ({ providerId, data }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const metadata = {
    // createdBy: entityId,
    //modifiedAt:'',
    //modifiedBy:'',
    isActive: true,
    createdAt: new Date(),
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    .insertOne({
      metadata: metadata,
      name: {
        // ar: data.arabicName,
        en: data.englishName,
      },
      providerId: providerId,
      isApproved: true,
    })

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    })
    return { result, appServiceFun }
  }
}
export const updateAddons = async ({ providerId, item, data }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !item || !data) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          name: {
            ar: data.englishName,
            en: data.englishName,
          },
        },
      }
    )
  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    })
    return { result, appServiceFun }
  }
}
export const deleteAddons = async ({ providerId, modifierId }) => {
  // console.log("deleteAddons  providerId modifierId", providerId, modifierId)
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !modifierId) {
    return
  }
  if (typeof modifierId === "string") {
    modifierId = new ObjectId(modifierId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    .deleteOne({ _id: modifierId })
  const branchmodifiersResult = await getDBInstance(app)
    .collection("branchmodifiers")
    .deleteMany({ modifierId })

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    })
    return { result, appServiceFun, branchmodifiersResult }
  }
}

export const getProducts = async ({
  providerId,
  status,
  category,
  startPrice,
  endPrice,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const query = {
    $and: [{ providerId: providerId }],
  }

  if (status != "Status") {
    if (status === "Active") {
      query.$and.push({
        "metadata.isActive": true,
      })
    } else {
      query.$and.push({
        "metadata.isActive": false,
      })
    }
  }

  if (category) {
    query.$and.push({
      categoryId: new ObjectId(category),
    })
  }
  // console.log("startPrice", startPrice, "endPrice", endPrice)
  if (startPrice && endPrice) {
    query.$and.push({
      $or: [
        { salePrice: { $gte: startPrice, $lte: endPrice } },
        { price: { $gte: startPrice, $lte: endPrice } },
      ],
    })
  }

  // console.log("query", query)

  const result = await getDBInstance(app)
    .collection("menus")
    // .find({ providerId: providerId });
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "categories",
          localField: "categoryId",
          foreignField: "_id",
          as: "getCategories",
        },
      },
      {
        $unwind: "$getCategories",
      },
      { $sort: options.sort },
      { $skip: options.skip },
      { $limit: options.limit },
    ])
  // console.log("product:", result)
  return result
}

export const getProductsCount = async ({
  providerId,
  status,
  category,
  startPrice,
  endPrice,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const query = {
    $and: [{ providerId: providerId }],
  }

  if (status != "Status") {
    if (status === "Active") {
      query.$and.push({
        "metadata.isActive": true,
      })
    } else {
      query.$and.push({
        "metadata.isActive": false,
      })
    }
  }

  if (category) {
    query.$and.push({
      categoryId: new ObjectId(category),
    })
  }
  // console.log("startPrice", startPrice, "endPrice", endPrice)
  if (startPrice && endPrice) {
    query.$and.push({
      $or: [
        { salePrice: { $gte: startPrice, $lte: endPrice } },
        { price: { $gte: startPrice, $lte: endPrice } },
      ],
    })
  }

  // console.log("query", query)

  const result = await getDBInstance(app).collection("menus").count(query)

  return result
}

export const getFilteringProducts = async ({ providerId, searchName }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [{ providerId: providerId }, { "metadata.isActive": true }],
  }

  if (searchName) {
    query.$and.push({ "name.en": { $regex: `^${searchName}`, $options: "i" } })
  }

  const result = await getDBInstance(app)
    .collection("menus")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "categories",
          localField: "categoryId",
          foreignField: "_id",
          as: "getCategories",
        },
      },
      {
        $unwind: "$getCategories",
      },
      {
        $sort: {
          _id: -1,
        },
      },

      {
        $replaceRoot: {
          newRoot: {
            label: "$name.en",
            _id: "$_id",
            name: "$name",
            description: "$description",
            providerId: "$providerId",
            mainImage: "$mainImage",
            price: "$price",
            salePrice: "$salePrice",
            categoryId: "$categoryId",
            subCategoryId: "$subCategoryId",
            getCategories: "$getCategories",
          },
        },
      },
    ])

  return result
}
export const getProductsModifiers = async ({ menuId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!menuId) {
    return
  }
  if (typeof menuId === "string") {
    menuId = new ObjectId(menuId)
  }

  const result = await getDBInstance(app)
    .collection("menumodifiers")
    // .find({ menuId: menuId });
    .aggregate([
      {
        $match: {
          menuId: menuId,
        },
      },
      {
        $lookup: {
          from: "modifiers",
          localField: "modifierId",
          foreignField: "_id",
          as: "getModifiers",
        },
      },
      {
        $unwind: "$getModifiers",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}
export const addProduct = async ({
  providerId,
  data,
  resIcon,
  multipleImages,
  categoryId,
  subCategoryId,
  badge,
  selectedVariants,
  productType,
  variantCombinations,
  modifierData,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data || !categoryId) {
    return
  }
  let variantsTransformedArray
  if (selectedVariants) {
    variantsTransformedArray = selectedVariants?.map(obj => ({
      _id: obj.value,
      availableOptions: obj.availableOptions?.map(option => option.value),
    }))
  }
  let variantCombinationsArray
  if (variantCombinations) {
    variantCombinationsArray = variantCombinations.map(product => ({
      ...product,
      variantOptions: product.variantOptions.map(option => ({
        variantId: option.variantId,
        optionId: option.optionId,
      })),
    }))
  }

  // console.log("variantCombinationsArray:", variantCombinationsArray)

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (categoryId && typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId)
  }

  if (subCategoryId && typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId)
  }

  //parseInt
  if (data.salePrice && typeof data.salePrice === "string") {
    data.salePrice = parseInt(data.salePrice)
  }
  const metadata = {
    createdBy: null,
    modifiedAt: null,
    modifiedBy: null,
    isActive: true,
    createdAt: new Date(),
  }
  let modifierResult
  let appServiceFun
  const result = await getDBInstance(app)
    .collection("menus")
    .insertOne({
      metadata: metadata,
      name: {
        // ar: data.arabicName,
        en: data.englishName,
      },
      description: {
        // ar: data.arabicDescription,
        en: data.englishDescription,
      },
      providerId: providerId,
      isApproved: true,
      mainImage: resIcon,
      badge: badge,
      media: multipleImages,
      price: data.price,
      salePrice: data.salePrice ? data.salePrice : null,
      // maximumModifiers: data.maximumAddons,
      // minimumModifiers: data.minimumAddons,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      attributes: variantsTransformedArray,
      type: productType,
      variantCombinations: variantCombinationsArray,
    })

  if (result) {
    appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    })
    // return { result, appServiceFun, menuModifier: modifierResult };
  }

  if (result?.insertedId && modifierData && modifierData.length !== 0) {
    // console.log("Addresult::", result);
    // console.log("insertedId::", result.insertedId);
    // console.log("modi::", modifierData);
    const newArray = modifierData.map(obj => {
      return {
        ...obj, // spread operator to copy existing properties of each object
        menuId: result.insertedId, // add a new field and value to each object
        metadata: {
          isActive: true,
          createdAt: new Date(),
        },
      }
    })
    // console.log("newArrayModifier::", newArray);
    if (newArray) {
      const resultArray = newArray.map(obj => {
        const { name, ...rest } = obj // using object destructuring to remove the age field
        return rest
      })
      // console.log("resultArray:", resultArray);
      if (resultArray) {
        modifierResult = await getDBInstance(app)
          .collection("menumodifiers")
          .insertMany(resultArray)
      }
    }

    return { result, appServiceFun, menuModifier: modifierResult }
  } else {
    return { result, appServiceFun, menuModifier: modifierResult }
  }
}
export const updateProduct = async ({
  providerId,
  item,
  data,
  resIcon,
  badge,
  multipleImages,
  categoryId,
  subCategoryId,
  selectedVariants,
  productType,
  variantCombinations,
  modifierData,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !item || !data) {
    return
  }
  // console.log("badge:", badge)

  let variantsTransformedArray
  if (selectedVariants) {
    variantsTransformedArray = selectedVariants?.map(obj => ({
      _id: obj.value,
      availableOptions: obj.availableOptions?.map(option => option.value),
    }))
  }
  let variantCombinationsArray
  if (variantCombinations) {
    variantCombinationsArray = variantCombinations.map(product => ({
      ...product,
      variantOptions: product.variantOptions.map(option => ({
        variantId: option.variantId,
        optionId: option.optionId,
      })),
    }))
  }

  // console.log("variantCombinationsArray:", variantCombinationsArray)

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  if (categoryId && typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId)
  }

  if (subCategoryId && typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId)
  }

  let modifierResult
  let menuModifierResult
  let appServiceFun

  //parseInt
  if (data.salePrice && typeof data.salePrice === "string") {
    data.salePrice = parseInt(data.salePrice)
  }

  const result = await getDBInstance(app)
    .collection("menus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.modifiedAt": new Date(),
          name: {
            // ar: data.arabicName,
            en: data.englishName,
          },
          description: {
            // ar: data.arabicDescription,
            en: data.englishDescription,
          },
          mainImage: resIcon,
          badge: badge,
          media: multipleImages,
          price: data.price,
          salePrice: data.salePrice ? data.salePrice : null,
          // maximumModifiers: data.maximumAddons,
          // minimumModifiers: data.minimumAddons,
          categoryId: categoryId,
          subCategoryId: subCategoryId,
          attributes: variantsTransformedArray,
          type: productType,
          variantCombinations: variantCombinationsArray,
        },
      }
    )
  if (result) {
    appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    })
  }

  if (result && modifierData && modifierData.length !== 0) {
    menuModifierResult = await getDBInstance(app)
      .collection("menumodifiers")
      .deleteMany({ menuId: item._id })

    if (menuModifierResult) {
      const newArray = modifierData.map(obj => {
        return {
          ...obj, // spread operator to copy existing properties of each object
          menuId: item._id, // add a new field and value to each object
          metadata: {
            isActive: true,
            createdAt: new Date(),
          },
        }
      })
      // console.log("newArrayModifier::", newArray);

      if (newArray) {
        const resultArray = newArray.map(obj => {
          const { getModifiers, name, ...rest } = obj // using object destructuring to remove the age field
          return rest
        })
        // console.log("resultArray:", resultArray);
        if (resultArray) {
          modifierResult = await getDBInstance(app)
            .collection("menumodifiers")
            .insertMany(resultArray)
        }
      }
    }
    return { result, appServiceFun, menuModifierResult, modifierResult }
  } else {
    return { result, appServiceFun, menuModifierResult, modifierResult }
  }
}

export const deleteProduct = async ({ providerId, menuId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !menuId) {
    return
  }
  if (typeof menuId === "string") {
    menuId = new ObjectId(menuId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const menuResult = await getDBInstance(app)
    .collection("menus")
    .deleteOne({ _id: menuId })
  const menuModifierResult = await getDBInstance(app)
    .collection("menumodifiers")
    .deleteMany({ menuId })
  const branchMenuResult = await getDBInstance(app)
    .collection("branchmenus")
    .deleteMany({ menuId })

  if (menuResult) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    })
    return { menuResult, menuModifierResult, appServiceFun, branchMenuResult }
  }
}
export const getSpecificSubcatogoriesSetting = async ({ subCategoryId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!subCategoryId) {
    return
  }
  if (typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId)
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    // .find({ categoryId: subCategoryId });
    .aggregate([
      {
        $match: {
          _id: subCategoryId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])

  return result[0]
}
export const getBranches = async ({ providerId }) => {
  // console.log("getBranches providerId", providerId)
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("branches")
    .find({ providerId: providerId }, queryOptions)

  return result
}
export const addBranch = async ({ providerId, data }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  // if (typeof cityId === "string") {
  //   cityId = new ObjectId(cityId);
  // }

  // console.log("geoCode::", typeof geoCode.lng);

  const metadata = {
    createdBy: null,
    isActive: true,
    createdAt: new Date(),
    modifiedBy: null,
  }

  const result = await getDBInstance(app)
    .collection("branches")
    .insertOne({
      metadata: metadata,
      name: {
        en: data.englishName,
      },
      email: data.email,
      number: data.number,
      username: data.username,
      prefix: data.prefix.toUpperCase(),
      address: data.branchAddress,
      deliveryCharge: data.deliveryCharge,
      // city: cityId,
      isApproved: true,
      providerId: providerId,
      // geo: {
      //   coordinates: [geoCode.lng, geoCode.lat],
      //   type: "Point",
      // },
    })
  if (result && result.insertedId) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId: result.insertedId },
    })
    return { result, appServiceFun }
  }
}
export const getBranchCity = async () => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("cities")
    .aggregate([
      {
        $match: {
          // $or: [
          //   { "name.en": { $regex: `^${search}`, $options: "i" } },
          //   { "name.ar": { $regex: `^${search}`, $options: "i" } },
          // ],
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name",
          },
        },
      },
    ])
  return result
}
export const getPrefixCheck = async ({ fieldValue, branchId }) => {
  if (!app || !app.currentUser) {
    const credentials = Realm.Credentials.anonymous()
    const test = await app.logIn(credentials)
  }

  let query = [
    {
      $match: {
        $or: [
          { prefix: fieldValue?.toUpperCase() },
          { number: fieldValue?.toString() },
          { email: fieldValue?.toString() },
        ],
      },
    },
  ]

  if (branchId) {
    query = [
      {
        $match: {
          $and: [
            {
              _id: { $ne: branchId },
            },
            {
              $or: [
                { prefix: fieldValue?.toUpperCase() },
                { number: fieldValue?.toString() },
              ],
            },
          ],
        },
      },
    ]
  }

  const result = await app.currentUser
    .mongoClient("mongodb-atlas")
    .db(envConfig.MONGODB_DB)
    .collection("branches")
    // .find({ categoryId: providerId });
    .aggregate(query)

  return result
}
export const getBranchCitySetting = async ({ cityId }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (typeof cityId === "string") {
    cityId = new ObjectId(cityId)
  }
  const result = await getDBInstance(app)
    .collection("cities")
    .aggregate([
      {
        $match: {
          _id: cityId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            // id: "$_id",
            label: "$name",
          },
        },
      },
    ])
  return result[0]
}
export const getBranchesSearch = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("branches")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en"],
            // },
          },
        },
      },
    ])
  return result
}
export const getProductCategorySearch = async ({ search, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .aggregate([
      {
        $match: {
          $or: [
            { "name.en": { $regex: `^${search}`, $options: "i" } },
            // { "name.ar": { $regex: `^${search}`, $options: "i" } },
          ],
          "metadata.isActive": true,
          providerId,
        },
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])
  // .aggregate([
  //   {
  //     $match: {
  //       $or: [
  //         { "name.en": { $regex: `^${search}`, $options: "i" } },
  //         { "name.ar": { $regex: `^${search}`, $options: "i" } },
  //       ],
  //       "metadata.isActive": true,
  //     },
  //   },
  //   {
  //     $lookup: {
  //       from: "categories",
  //       localField: "categoryId",
  //       foreignField: "_id",
  //       as: "getCategories",
  //     },
  //   },
  //   {
  //     $match: {
  //       $and: [
  //         { "getCategories.metadata.isActive": true },
  //         { "getCategories.type": "home-business" && "food" },
  //       ],
  //     },
  //   },
  //   {
  //     $unwind: "$getCategories",
  //   },
  //   {
  //     $replaceRoot: {
  //       newRoot: {
  //         subCategoryId: "$_id",
  //         categoryId:"$getCategories._id",
  //         label: {
  //           $concat: ["$getCategories.name.en", "(", "$getCategories.name.ar", ")"],
  //         },
  //       },
  //     },
  //   },
  // ]);
  return result
}

export const getReviews = async ({ providerId }) => {
  if (!app && !app.currentUser) {
    return
  }
  try {
    return await getDBInstance(app)
      .collection("reviews")
      .find({ reviewedOn: { entity: "provider", entityId: providerId } })
  } catch (e) {}
}

export const getAddons = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    // .find({ categoryId: providerId });
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])

  return result
}

export const getSpecificSubcatogories = async ({ subId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!subId) {
    return
  }
  if (typeof subId === "string") {
    subId = new ObjectId(subId)
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    // .find({ categoryId: subId });
    .aggregate([
      {
        $match: {
          categoryId: subId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])

  return result
}

export const getOneOrderDetail = async ({ orderId }) => {
  // console.log("getorderdetails id", orderId)
  if (!app || !app.currentUser) {
    // alert("!app || !app.currentUser")
    return
  }

  if (!orderId) {
    return
  }
  if (typeof orderId === "string") {
    orderId = new ObjectId(orderId)
  }
  // console.log("db orderid:", orderId)

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          _id: orderId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
    ])
  return result[0]
}

export const updateProvider = async ({
  providerId,
  data,
  resIcon,
  resBanner,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .updateOne(
      {
        _id: providerId,
      },
      {
        $set: {
          email: data.email,
          number: data.number,
          whatsapp: data.whatsapp,
          facebook: data.facebook,
          instagram: data.instagram,
          username: data.username,
          description: {
            en: data.englishBio,
          },
          name: {
            en: data.englishName,
          },
          // primaryColor: data.primaryColor,
          // secondaryColor: data.secondaryColor,
          // cartTextColor: data.cartTextColor,
          // headerBackgroundColor: data.headerBackgroundColor,
          // pageBackgroundColor: data.pageBackgroundColor,
          // primaryTextColor: data.primaryTextColor,
          // secondaryTextColor: data.secondaryTextColor,
          // borderColor: data.borderColor,
          icon: resIcon,
          mainImage: resBanner,
          upiId: data.upiId,
        },
      }
    )

  return result
}

export const getProvider = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    // alert('!app || !app.currentUser',!app || !app.currentUser)
    return
  }

  if (!providerId) {
    // alert('!providerId',!providerId)
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .findOne({ _id: providerId })

  return result
}

export const getOrdersBtwDates = async ({
  startDate,
  endDate,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }
  if (startDate && endDate) {
    query.$and.push({
      "metadata.createdAt": { $gt: startDate },
    })
    query.$and.push({
      "metadata.createdAt": { $lt: endDate },
    })
  }

  // console.log("query:", query);

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getBookingsBtwDates = async ({
  startDate,
  endDate,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }
  if (startDate && endDate) {
    query.$and.push({
      "metadata.createdAt": { $gt: startDate },
    })
    query.$and.push({
      "metadata.createdAt": { $lt: endDate },
    })
  }

  // console.log("query:", query);

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisyear = async ({
  startOfYear,
  endOfYear,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfYear, endOfYear);
  if (!providerId || !startOfYear || !endOfYear) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfYear },
      },
      {
        "metadata.createdAt": { $lt: endOfYear },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }
  // if (typeof providerId === "string") {
  //   providerId = new ObjectId(providerId);
  // }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisMonth = async ({
  startOfMonth,
  endOfMonth,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfMonth, endOfMonth);
  if (!providerId || !startOfMonth || !endOfMonth) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfMonth },
      },
      {
        "metadata.createdAt": { $lt: endOfMonth },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisWeek = async ({
  startOfWeek,
  endOfWeek,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfWeek, endOfWeek);
  if (!providerId || !startOfWeek || !endOfWeek) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfWeek },
      },
      {
        "metadata.createdAt": { $lt: endOfWeek },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }
  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const getOrdersThisDay = async ({
  startTodays,
  endTodays,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startTodays, endTodays);
  if (!providerId || !startTodays || !endTodays) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startTodays },
      },
      {
        "metadata.createdAt": { $lt: endTodays },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])
  return result
}

export const updateIsActive = async ({
  item,
  isActive,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!item) {
    return
  }

  // console.log("itemid:", item._id.toString());

  const result = await getDBInstance(app)
    .collection("branchmenus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.isActive": isActive,
        },
      }
    )

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId },
    })
    return { result, appServiceFun }
  }
}
export const updateReviewIsActive = async ({ isActive, id }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("reviews")
    .updateOne(
      { _id: id },

      {
        $set: {
          "metaData.isActive": isActive,
        },
      }
    )
}

export const updateIsStock = async ({
  item,
  isStock,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!item) {
    return
  }

  // console.log("itemid:", item._id.toString());

  const result = await getDBInstance(app)
    .collection("branchmenus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          inStock: isStock,
        },
      }
    )

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId },
    })
    return { result, appServiceFun }
  }
}

export const getAudience = async ({
  providerId,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $lookup: {
          from: "message",
          let: { chatId: "$_id" }, 
          pipeline: [
            {
              $match: {
                $expr: {
                  $and: [
                    { $eq: ["$chatId", "$$chatId"] }, 
                    { $eq: ["$senderEntity", "customer"] }, 
                    { $eq: ["$read", false] },
                  ],
                },
              },
            },
          ],
          as: "messages",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
          message: { $first: "$messages" },
        },
      },
      { $sort: options.sort },
      { $skip: options.skip },
      { $limit: options.limit },
    ])

  return result
}

export const getAllAudience = async ({
  providerId
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $lookup: {
          from: "message",
          let: { chatId: "$_id" }, 
          pipeline: [
            {
              $match: {
                $expr: {
                  $and: [
                    { $eq: ["$chatId", "$$chatId"] },
                    { $eq: ["$senderEntity", "customer"] }, 
                    { $eq: ["$read", false] },
                  ],
                },
              },
            },
          ],
          as: "messages",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
          message: { $first: "$messages" },
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])

  return result
}

export const getAudienceCount = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  return result
  //{ $count: "count" },
}

export const getAudienceOrder = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  const result1 = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  const combinedResults = [...result, ...result1]

  // Use a Set to eliminate duplicate customers
  const uniqueCustomersSet = new Set()

  const uniqueCustomers = combinedResults?.filter(entry => {
    if (!uniqueCustomersSet.has(entry.customer?._id?.toString())) {
      uniqueCustomersSet.add(entry.customer?._id?.toString())
      return true
    }
    return false
  })

  return uniqueCustomers
}

export const getChatList = async ({ providerId, branchId, customerId }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!providerId || !branchId || !customerId) {
    return
  }

  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId,
          branchId,
          customerId,
        },
      },
      {
        $lookup: {
          from: "message",
          localField: "messages",
          foreignField: "_id",
          pipeline: [
            {
              $sort: {
                sendOn: 1,
              },
            },
          ],
          as: "getMessage",
        },
      },
      {
        $lookup: {
          from: "providers",
          localField: "providerId",
          foreignField: "_id",
          as: "getProvider",
        },
      },
      {
        $unwind: "$getProvider",
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      {
        $unwind: "$getCustomer",
      },
    ])
  return result[0]
}

export const createNewMessage = async ({
  message,
  providerId,
  branchId,
  customerId,
  senderEntity,
  type,
  data,
  image,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!providerId || !branchId || !customerId || !senderEntity) {
    return
  }

  if (
    !isValidObjectId(providerId) ||
    !isValidObjectId(branchId) ||
    !isValidObjectId(customerId)
  ) {
    console.error("Invalid ObjectId format")
    return // Stop execution if the IDs are not valid
  }

  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const params = {
    message,
    providerId,
    branchId,
    customerId,
    senderEntity,
    type,
    data,
    image,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("chat", {
    functionName: "createMessage",
    params: params,
  })
  return result
}

const isValidObjectId = id => /^[a-f\d]{24}$/i.test(id)
export const updateReadMessage = async ({ chatId }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (typeof chatId === "string") {
    chatId = new ObjectId(chatId)
  }
  // console.log("updateNewChat chatId==", chatId)
  const result = await getDBInstance(app)
    .collection("message")
    .updateMany(
      { chatId: chatId, senderEntity: "customer", read: false },
      {
        $set: {
          read: true,
          readTime: new Date(),
        },
      }
    )
  return result
}

export const getCustomerOrders = async ({ customerId, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!customerId || !providerId) {
    return
  }
  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          customerId: customerId,
          providerId: providerId,
        },
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const getCustomerBookings = async ({ customerId, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!customerId || !providerId) {
    return
  }
  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: {
          customerId: customerId,
          providerId: providerId,
        },
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result
}

export const getCustomerDetails = async ({ customerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!customerId) {
    return
  }
  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  }
  // console.log(customerId)
  const result = await getDBInstance(app)
    .collection("customers")
    .findOne({ _id: customerId }, queryOptions)

  return result
}

export const createNote = async ({ orderId, content }) => {
  if (!app || !app.currentUser) {
    return
  }

  // let notes = [];
  let noteToAdd = null
  if (content?.length > 0) {
    noteToAdd = {
      noteBy: "provider",
      message: content,
      date: new Date(),
    }
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .updateOne(
      {
        _id: orderId,
      },
      {
        $push: {
          notes: noteToAdd,
        },
      }
    )
  return result
}

export const noteDelete = async ({ orderId, tempArray }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .updateOne(
      {
        _id: orderId,
      },
      {
        $set: {
          notes: tempArray,
        },
      }
    )
  return result
}

export const getInCompleteOrders = async ({ providerId, customerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !customerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          providerId: providerId,
          status: { $nin: ["completed", "cancelled", "refunded"] },
          customerId: customerId,
          // paymentStatus: { $nin: ["Paid", "completed"] },
        },
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            totalAmount: "$payments.amountPayable",
            label: "$refNumber",
            _id: "$_id",
          },
        },
      },
    ])

  return result
}

export const updateAmountPayable = async ({
  orderId,
  providerId,
  amount,
  discount,
  additionalCharges,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!orderId || !providerId || !amount) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (typeof orderId === "string") {
    orderId = new ObjectId(orderId)
  }
  // console.log(orderId, providerId, amount)

  const result = await getDBInstance(app)
    .collection("orders")
    .updateOne(
      {
        _id: orderId,
      },
      {
        $set: {
          amount,
          "payments.amountPayable": amount,
          "payments.discount": discount ? discount : 0,
          "payments.additionalCharges": additionalCharges
            ? additionalCharges
            : 0,
        },
      }
    )
  return result
}

export const updateUpiId = async ({ providerId, upiId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !upiId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app).collection("providers").updateOne(
    {
      _id: providerId,
    },
    {
      $set: {
        upiId,
      },
    }
  )
  return result
}

export const getLastOrder = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result[0]
}

export const createOneCustomer = async ({ data }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!data) {
    return
  }

  const result = await getDBInstance(app).collection("customers").insertOne({
    firstName: data.firstName,
    lastName: data.lastName,
    number: data.number,
    email: data.email,
  })
  return result
}

export const getAddress = async ({ entityId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!entityId) {
    return
  }

  const queryOptions = {
    sort: { _id: -1 },
  }
  const result = await getDBInstance(app)
    .collection("addresses")
    .find({ entityId: entityId }, queryOptions)
  return result
}

export const createCustomerAddress = async ({ entityId, data }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!entityId || !data) {
    return
  }

  const metadata = {
    createdBy: new ObjectId(entityId),
    isActive: true,
    createdAt: new Date(),
  }

  const result = await getDBInstance(app)
    .collection("addresses")
    .insertOne({
      name: `${data.firstName} ${data.lastName}`,
      number: data.number,
      pincode: data.pincode,
      state: data.state,
      town: data.town,
      district: data.district,
      address: data.address,
      email: data.email,
      type: "home",
      entity: "customer",
      entityId: entityId.toString(),
      metadata: metadata,
    })
  return result
}
export const CustomerOneAddress = async ({ entityId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!entityId) {
    return
  }
  const result = await getDBInstance(app)
    .collection("addresses")
    .findOne({ entityId: entityId })

  return result
}

export const orderCreate = async ({
  branchId,
  customerId,
  providerId,
  amount,
  address,
  providerType,
  items,
  payments,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }

  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const gUser = await generalLogin()

  return await gUser?.callFunction("orders", {
    functionName: "orderCreate",
    params: {
      branchId,
      customerId,
      providerId,
      amount,
      address,
      providerType,
      items,
      payments,
      notes: [],
    },
  })
}

export const createCouponCode = async ({
  providerId,
  couponName,
  couponDescription,
  couponCode,
  includedCategoryTypes,
  excludedCategoryTypes,
  includedProductTypes,
  excludedProductTypes,
  includedCustomers,
  excludedCustomers,
  type,
  amount,
  validTill,
  validFrom,
  toggleSwitch,
  isActive,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  try {
    const result = await getDBInstance(app)
      .collection("coupons")
      .insertOne({
        providerId: providerId,
        name: couponName,
        description: couponDescription,
        couponCode: couponCode,
        includedCategoryTypes: includedCategoryTypes,
        excludedCategoryTypes: excludedCategoryTypes,
        includedProductTypes: includedProductTypes,
        excludedProductTypes: excludedProductTypes,
        includedCustomers: includedCustomers,
        excludedCustomers: excludedCustomers,
        type: type,
        amount: Number(amount),
        validFrom: new Date(validFrom),
        validTill: new Date(validTill),
        toggleSwitch: toggleSwitch,
        isActive: isActive,
      })

    return result
  } catch (e) {
    // console.log("Error :", e)
  }
}

export const updateCoupon = async ({
  editId,
  couponName,
  couponDescription,
  couponCode,
  includedCategoryTypes,
  excludedCategoryTypes,
  includedProductTypes,
  excludedProductTypes,
  includedCustomers,
  excludedCustomers,
  type,
  amount,
  validFrom,
  validTill,
  toggleSwitch,
  isActive,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  const result = await getDBInstance(app)
    .collection("coupons")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: couponName,
          description: couponDescription,
          couponCode: couponCode,
          includedCategoryTypes: includedCategoryTypes,
          excludedCategoryTypes: excludedCategoryTypes,
          includedProductTypes: includedProductTypes,
          excludedProductTypes: excludedProductTypes,
          includedCustomers: includedCustomers,
          excludedCustomers: excludedCustomers,
          type: type,
          amount: Number(amount),
          validFrom: new Date(validFrom),
          validTill: new Date(validTill),
          toggleSwitch: toggleSwitch,
          isActive: isActive,
        },
      }
    )
  return result
}

export const getCouponCode = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app)
    .collection("coupons")
    .aggregate([
      {
        $match: {
          _id: id,
        },
      },
      {
        $lookup: {
          from: "categories",
          localField: "includedCategoryTypes",
          foreignField: "_id",
          as: "getIncludedCategoryTypes",
        },
      },
      {
        $lookup: {
          from: "categories",
          localField: "excludedCategoryTypes",
          foreignField: "_id",
          as: "getExcludedCategoryTypes",
        },
      },
      {
        $lookup: {
          from: "menus",
          localField: "includedProductTypes",
          foreignField: "_id",
          as: "getIncludedProductTypes",
        },
      },
      {
        $lookup: {
          from: "menus",
          localField: "excludedProductTypes",
          foreignField: "_id",
          as: "getExcludedProductTypes",
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "includedCustomers",
          foreignField: "_id",
          as: "getIncludedCustomers",
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "excludedCustomers",
          foreignField: "_id",
          as: "getExcludedCustomers",
        },
      },
    ])
  // console.log("agg res===", result)
  return result
}

export const getCoupons = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const response = await getDBInstance(app)
    .collection("coupons")
    .find({ providerId: providerId })
  // console.log("response ==", response)
  return response
}

export const deleteCoupons = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("coupons").deleteOne({ _id: id })
}

export const getOneMenu = async ({ menuId }) => {
  // console.log("got value", menuId);
  if (!app || !app.currentUser) {
    return
  }

  if (!menuId) {
    return null
  }
  if (typeof menuId === "string") {
    menuId = new ObjectId(menuId)
  }

  const result = await getDBInstance(app)
    .collection("menus")
    .aggregate([
      {
        $match: { _id: menuId },
      },
      {
        $lookup: {
          from: "attributes",
          localField: "attributes._id",
          foreignField: "_id",
          as: "attributesData",
        },
      },
      {
        $lookup: {
          from: "attributeOptions",
          localField: "attributes.availableOptions",
          foreignField: "_id",
          as: "availableOptionsData",
        },
      },
      {
        $lookup: {
          from: "attributeOptions",
          localField: "variantCombinations.variantOptions.optionId",
          foreignField: "_id",
          as: "availableVariantOptionsData",
        },
      },
      {
        $lookup: {
          from: "attributes",
          localField: "variantCombinations.variantOptions.variantId",
          foreignField: "_id",
          as: "availableVariantOptionsDataV",
        },
      },
      {
        $addFields: {
          variantCombinations: {
            $map: {
              input: "$variantCombinations",
              as: "combination",
              in: {
                $mergeObjects: [
                  "$$combination",
                  {
                    variantOptions: {
                      $map: {
                        input: "$$combination.variantOptions",
                        as: "variantOption",
                        in: {
                          $mergeObjects: [
                            "$$variantOption",
                            {
                              optionLabel: {
                                $arrayElemAt: [
                                  {
                                    $filter: {
                                      input: "$availableVariantOptionsData",
                                      as: "avOption",
                                      cond: {
                                        $eq: [
                                          "$$avOption._id",
                                          "$$variantOption.optionId",
                                        ],
                                      },
                                    },
                                  },
                                  0,
                                ],
                              },
                              variantLabel: {
                                $arrayElemAt: [
                                  {
                                    $filter: {
                                      input: "$availableVariantOptionsDataV",
                                      as: "avOption",
                                      cond: {
                                        $eq: [
                                          "$$avOption._id",
                                          "$$variantOption.variantId",
                                        ],
                                      },
                                    },
                                  },
                                  0,
                                ],
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                  // {
                  //   variantOptions: {
                  //     $map: {
                  //       input: "$$combination.variantOptions",
                  //       as: "variantOption",
                  //       in: {
                  //         $mergeObjects: [
                  //           "$$variantOption",
                  //           {
                  //             variantLabel: {
                  //               $arrayElemAt: [
                  //                 {
                  //                   $filter: {
                  //                     input: "$availableVariantOptionsDataV",
                  //                     as: "avOption",
                  //                     cond: {
                  //                       $eq: [
                  //                         "$$avOption._id",
                  //                         "$$variantOption.variantId",
                  //                       ],
                  //                     },
                  //                   },
                  //                 },
                  //                 0,
                  //               ],
                  //             },
                  //           },
                  //         ],
                  //       },
                  //     },
                  //   },
                  // },
                ],
              },
            },
          },
        },
      },
      {
        $project: {
          _id: 1,
          type: 1,
          subCategoryId: 1,
          salePrice: 1,
          providerId: 1,
          price: 1,
          name: 1,
          metadata: 1,
          media: 1,
          mainImage: 1,
          isApproved: 1,
          description: 1,
          categoryId: 1,
          attributes: {
            $map: {
              input: "$attributesData",
              as: "attr",
              in: {
                _id: "$$attr._id",
                name: "$$attr.name",
                attributeOptions: "$attributeOptions",
                availableOptions: {
                  $filter: {
                    input: "$availableOptionsData",
                    as: "option",
                    cond: {
                      $eq: ["$$option.attributeId", "$$attr._id"],
                    },
                  },
                },
              },
            },
          },

          availableVariantOptionsData: 1,
          variantCombinations: 1,
          badge: 1,
          availableVariantOptionsDataV: 1,
        },
      },
    ])
  return result[0]
}

export const getProductVariants = async ({ providerId, isSelectOption }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  let query = [
    {
      $match: {
        providerId: providerId,
      },
    },
    {
      $lookup: {
        from: "attributeOptions",
        localField: "_id",
        foreignField: "attributeId",
        as: "attributeOptions",
      },
    },
  ]

  if (isSelectOption) {
    query.push(
      {
        $addFields: {
          value: "$_id",
          label: "$name.en",
          // data: {
          //   _id: "$_id",
          //   name: "$name",
          //   attributeOptions: "$attributeOptions",
          // },
          attributeOptions: {
            $map: {
              input: "$attributeOptions",
              as: "option",
              in: {
                value: "$$option._id",
                label: "$$option.value",
              },
            },
          },
        },
      },
      {
        $project: {
          _id: 0,
          value: 1,
          label: 1,
          // data: 1,
          attributeOptions: 1,
        },
      }
    )
  } else {
    query.push({
      $project: {
        _id: 1,
        name: 1,
        attributeOptions: {
          $map: {
            input: "$attributeOptions",
            as: "option",
            in: {
              _id: "$$option._id",
              value: "$$option.value",
            },
          },
        },
      },
    })
  }

  query.push({
    $sort: {
      _id: -1,
    },
  })

  const result = await getDBInstance(app)
    .collection("attributes")
    .aggregate(query)

  return result
}
export const getOneProductVariants = async ({ itemId, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }

  if (!itemId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  // const result = await getDBInstance(app)
  //   .collection("variants")
  //   .findOne({ _id: itemId, providerId })

  const result = await getDBInstance(app)
    .collection("attributes")
    .aggregate([
      {
        $match: { _id: itemId, providerId },
      },
      {
        $lookup: {
          from: "attributeOptions",
          localField: "_id",
          foreignField: "attributeId",
          as: "attributeOptions",
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])

  return result[0]
}

export const addVariant = async ({ data, providerId, options }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!data || !providerId) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  if (options.length === 0) {
    return
  }

  const attributeResult = await getDBInstance(app)
    .collection("attributes")
    .insertOne({
      name: {
        en: data.englishName,
      },
      options: [],
      providerId,
    })

  const attributeId = attributeResult.insertedId

  const optionsWithAttributeId = options.map(option => ({
    ...option,
    attributeId,
    providerId,
  }))

  const optionInsertResult = await getDBInstance(app)
    .collection("attributeOptions")
    .insertMany(optionsWithAttributeId)

  const optionIds = optionInsertResult.insertedIds

  const result = await getDBInstance(app)
    .collection("attributes")
    .updateOne({ _id: attributeId }, { $set: { options: optionIds } })

  return result
}

export const updateVariant = async ({ id, data, providerId, options }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!id || !data || !providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  if (typeof id === "string") {
    id = new ObjectId(id)
  }
  if (options.length === 0) {
    return
  }

  // Fetch the attribute document
  const attribute = await getDBInstance(app)
    .collection("attributes")
    .findOne({ _id: id })

  if (!attribute) {
    console.error("Attribute not found")
    return
  }

  const existingOptions = attribute.options || []

  const filteredOptions1 = options.filter(obj => "_id" in obj && "value" in obj)

  const existingOptionIds = new Set(
    existingOptions.map(option => option.toHexString())
  )

  const remainingOptionIds = filteredOptions1
    .filter(newOption => !existingOptionIds.has(newOption._id))
    .map(newOption => newOption._id)

  const result = await getDBInstance(app)
    .collection("attributeOptions")
    .deleteMany({ _id: { $nin: remainingOptionIds }, attributeId: id })

  const attributeResult = await getDBInstance(app)
    .collection("attributes")
    .updateOne(
      { _id: id },
      {
        $set: {
          name: {
            en: data.englishName,
          },
          options: remainingOptionIds,
          providerId,
        },
      }
    )

  const filteredOptions = options.filter(obj => !("_id" in obj))

  const optionsWithAttributeId = filteredOptions?.map(option => ({
    ...option,
    attributeId: id,
    providerId,
  }))

  if (optionsWithAttributeId?.length > 0) {
    const optionInsertResult = await getDBInstance(app)
      .collection("attributeOptions")
      .insertMany(optionsWithAttributeId)

    const optionIds = optionInsertResult.insertedIds

    const attributeResult1 = await getDBInstance(app)
      .collection("attributes")
      .updateOne(
        { _id: id },
        {
          $push: {
            options: { $each: optionIds },
          },
        }
      )
  }

  return attributeResult
}

export const deleteVariant = async ({ itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("attributes")
    .deleteOne({ _id: itemId })
  await getDBInstance(app)
    .collection("attributeOptions")
    .deleteMany({ attributeId: itemId })

  return result
}

export const getRevenue = async ({ dateFrom, dateTo, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [{ status: "completed" }, { providerId: providerId }],
  }

  if (dateFrom) {
    query.$and.push({
      "metadata.createdAt": { $gt: dateFrom },
    })
  }
  if (dateTo) {
    query.$and.push({
      "metadata.createdAt": { $lt: dateTo },
    })
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $group: {
          _id: "$items.categoryId",
          count: {
            $sum: 1, // Count the documents
          },
          totalSaleAmount: {
            $sum: "$amount",
          },
        },
      },
      {
        $lookup: {
          from: "categories",
          localField: "_id",
          foreignField: "_id",
          as: "category",
        },
      },
      {
        $unwind: "$category", // Unwind the category array
      },
      {
        $group: {
          _id: "$category._id", // Group by the category _id
          count: {
            $sum: "$count", // Sum the counts for this category
          },
          totalSaleAmount: {
            $sum: "$totalSaleAmount", // Sum the totalSaleAmount for this category
          },
          category: {
            $push: "$category", // Push the category object into an array
          },
        },
      },
    ])

  return result
}

export const addServiceCategory = async ({
  providerId,
  data,
  resIcon,
  isActive,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .insertOne({
      name: {
        ar: data.englishName,
        en: data.englishName,
      },
      image: resIcon,
      isActive: isActive,
      providerId: providerId,
    })

  return result
}
export const updateServiceCategory = async ({
  itemId,
  data,
  resIcon,
  isActive,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!itemId) {
    return
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          name: {
            ar: data.englishName,
            en: data.englishName,
          },
          image: resIcon,
          isActive: isActive,
        },
      }
    )

  return result
}

export const deleteServiceCategory = async ({ providerId, itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !itemId) {
    return
  }
  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .deleteOne({ _id: itemId, providerId })

  return result
}

export const getServiceCategories = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const queryOptions = {
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .find({ providerId }, queryOptions)

  return result
}

export const getOneServiceCategory = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!id) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .findOne({ _id: id })

  return result
}

export const getServiceCategorySetting = async ({ categoryId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!categoryId) {
    return
  }
  if (typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .aggregate([
      {
        $match: {
          _id: categoryId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en"],
            },
          },
        },
      },
    ])
  return result[0]
}

export const getServiceCategorySearch = async ({ search, providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .aggregate([
      {
        $match: {
          $or: [
            { "name.en": { $regex: `^${search}`, $options: "i" } },
            { "name.ar": { $regex: `^${search}`, $options: "i" } },
          ],
          isActive: true,
          providerId,
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name.en",
            // label: {
            //   $concat: ["$name.en", "(", "$name.ar", ")"],
            // },
          },
        },
      },
    ])

  return result
}

export const addService = async ({
  providerId,
  data,
  resIcon,
  isActive,
  categoryId,
  duration,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  duration = new Date(duration)

  const result = await getDBInstance(app)
    .collection("services")
    .insertOne({
      name: {
        ar: data.englishName,
        en: data.englishName,
      },
      description: {
        ar: data.englishDes,
        en: data.englishDes,
      },
      image: resIcon,
      isActive: isActive,
      providerId: providerId,
      categoryId: categoryId,
      duration: { HH: duration.getHours(), MM: duration.getMinutes() },
      price: data.price,
      salePrice: data.salePrice ? parseInt(data.salePrice) : null,
    })

  return result
}
export const updateService = async ({
  itemId,
  data,
  resIcon,
  isActive,
  categoryId,
  duration,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!itemId) {
    return
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }
  duration = new Date(duration)

  const result = await getDBInstance(app)
    .collection("services")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          name: {
            ar: data.englishName,
            en: data.englishName,
          },
          description: {
            ar: data.englishDes,
            en: data.englishDes,
          },
          image: resIcon,
          isActive: isActive,
          categoryId: categoryId,
          duration: { HH: duration.getHours(), MM: duration.getMinutes() },
          price: data.price,
          salePrice: data.salePrice ? parseInt(data.salePrice) : null,
        },
      }
    )

  return result
}

export const deleteService = async ({ providerId, itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !itemId) {
    return
  }
  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("services")
    .deleteOne({ _id: itemId, providerId })

  return result
}

export const getServices = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const queryOptions = {
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("services")
    .aggregate([
      {
        $match: {
          providerId,
        },
      },
      {
        $lookup: {
          from: "serviceCategories",
          localField: "categoryId",
          foreignField: "_id",
          as: "serviceCategories",
        },
      },

      // {
      //   $unwind: '$serviceCategories',
      // },
      {
        $sort: {
          _id: -1,
        },
      },
    ])

  // const result1 = await getDBInstance(app)
  //   .collection('services')
  //   .find({providerId, categoryId: null}, queryOptions);

  // return [...result, ...result1];
  return result
}

export const getOneService = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!id) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app)
    .collection("services")
    .findOne({ _id: id })

  return result
}

export const addCMS = async ({ providerId, data, content, isActive }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const slug = await generateSlug({ title: data.name })

  const result = await getDBInstance(app)
    .collection("cms")
    .insertOne({
      name: {
        ar: data.name,
        en: data.name,
      },
      slug,
      content: content,
      isActive: isActive,
      providerId: providerId,
    })

  return result
}
export const updateCMS = async ({ itemId, data, content, isActive }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!itemId) {
    return
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }
  const slug = await generateSlug({ title: data.name })
  const result = await getDBInstance(app)
    .collection("cms")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          name: {
            ar: data.name,
            en: data.name,
          },
          slug,
          content: content,
          isActive: isActive,
        },
      }
    )

  return result
}

export const deleteCMS = async ({ providerId, itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !itemId) {
    return
  }
  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("cms")
    .deleteOne({ _id: itemId, providerId })

  return result
}

export const getCms = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const queryOptions = {
    sort: { _id: -1 },
  }

  const result = await getDBInstance(app)
    .collection("cms")
    .find({ providerId }, queryOptions)

  return result
}

export const getOneCms = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!id) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app).collection("cms").findOne({ _id: id })

  return result
}

export const generateSlug = ({ title }: any) => {
  // Convert to lowercase
  return (
    title
      // Convert to lowercase
      .toLowerCase()
      // Replace spaces with hyphens
      .replace(/\s+/g, "-")
      // Remove all non-word chars (keep only letters, numbers, and hyphens)
      .replace(/[^a-z0-9-]/g, "")
      // Replace multiple hyphens with a single hyphen
      .replace(/-+/g, "-")
  )
}

export const addTeamMember = async ({
  providerId,
  data,
  resIcon,
  isActive,
  categoryIds,
  serviceIds,
  startTime,
  endTime,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !data) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app).collection("teamMembers").insertOne({
    name: data.fullName,
    number: data.number,
    email: data.email,
    image: resIcon,
    isActive: isActive,
    providerId: providerId,
    categoryIds,
    serviceIds,
    startTime,
    endTime,
  })

  return result
}
export const updateTeamMember = async ({
  itemId,
  data,
  resIcon,
  isActive,
  categoryIds,
  serviceIds,
  startTime,
  endTime,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!itemId) {
    return
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  const result = await getDBInstance(app)
    .collection("teamMembers")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          name: data.fullName,
          number: data.number,
          email: data.email,
          image: resIcon,
          isActive: isActive,
          categoryIds,
          serviceIds,
          startTime: startTime === "Invalid Date" ? null : startTime,
          endTime: endTime === "Invalid Date" ? null : endTime,
        },
      }
    )

  return result
}

export const deleteTeamMember = async ({ providerId, itemId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId || !itemId) {
    return
  }
  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("teamMembers")
    .deleteOne({ _id: itemId, providerId })

  return result
}

export const getTeamMembers = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("teamMembers")
    .aggregate([
      {
        $match: {
          providerId,
        },
      },
      {
        $lookup: {
          from: "serviceCategories", // Name of the serviceCategories collection
          localField: "categoryIds",
          foreignField: "_id", // Assuming "_id" is the unique identifier in the serviceCategories collection
          as: "categories",
        },
      },
      {
        $lookup: {
          from: "services", // Name of the services collection
          localField: "serviceIds",
          foreignField: "_id", // Assuming "_id" is the unique identifier in the services collection
          as: "services",
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])

  return result
}

export const getOneTeamMember = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!id) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app)
    .collection("teamMembers")
    .aggregate([
      {
        $match: {
          _id: id,
        },
      },
      {
        $lookup: {
          from: "serviceCategories", // Replace with your actual collection name
          localField: "categoryIds",
          foreignField: "_id",
          as: "categories",
        },
      },
      {
        $lookup: {
          from: "services", // Replace with your actual collection name
          localField: "serviceIds",
          foreignField: "_id",
          as: "services",
        },
      },
    ])

  return result[0]
}

export const getServiceCategoryTeamMembers = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("serviceCategories")
    .aggregate([
      {
        $match: {
          isActive: true,
          providerId,
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            value: "$_id",
            label: {
              $concat: ["$name.en"],
            },
          },
        },
      },
    ])

  return result
}

export const getServicesTeamMembers = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("services")
    .aggregate([
      {
        $match: {
          isActive: true,
          providerId,
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            value: "$_id",
            label: {
              $concat: ["$name.en"],
            },
          },
        },
      },
    ])

  return result
}

export const getBookings = async ({
  providerId,
  status,
  paymentStatus,
  startDate,
  endDate,
  assignedTo,
  service,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [{ providerId: providerId }],
  }

  if (status === "all") {
    query.$and.push({
      status: {
        $in: [
          "pending",
          "accepted",
          "ready",
          "cancelled",
          "completed",
          "refunded",
        ],
      },
    })
  }

  // if (status === "pending") {
  //   query.$and.push({
  //     status: status,
  //   })
  // }

  // if (status === "accepted") {
  //   query.$and.push({
  //     status: status,
  //   })
  // }
  // if (status === "ready") {
  //   query.$and.push({
  //     status: status,
  //   })
  // }
  // if (status === "cancelled") {
  //   query.$and.push({
  //     status: status,
  //   })
  // }
  // if (status === "completed") {
  //   query.$and.push({
  //     status: status,
  //   })
  // }
  // if (status === "refunded") {
  //   query.$and.push({
  //     status: status,
  //   })
  // }
  // if (paymentStatus === "all") {
  //   query.$and.push({
  //     status: { $in: ["paid", "Paid", "completed", "pending"] },
  //   })
  // }
  // if (paymentStatus === "pending") {
  //   query.$and.push({
  //     paymentStatus: "pending",
  //   })
  // }
  // if (paymentStatus === "paid") {
  //   query.$and.push({
  //     paymentStatus: { $in: ["Paid", "paid"] },
  //   })
  // }


  else if (status) {
    query.$and.push({ status }) 
  }

  if (paymentStatus && paymentStatus !== "all") {
    query.$and.push({
      paymentStatus: paymentStatus.toLowerCase(),
    })
  }

  if (startDate) {
    query.$and.push({
      bookedDate: { $gt: startDate },
    })
  }
  if (endDate) {
    query.$and.push({
      bookedDate: { $lt: endDate },
    })
  }
  if (assignedTo) {
    if (typeof assignedTo === "string") {
      assignedTo = new ObjectId(assignedTo)
    }
    query.$and.push({
      serviceMemberId: assignedTo,
    })
  }
  if (service) {
    if (typeof service === "string") {
      service = new ObjectId(service)
    }
    query.$and.push({
      items: {
        $elemMatch: { _id: service },
      },
    })
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "teamMembers",
          localField: "serviceMemberId",
          foreignField: "_id",
          as: "serviceMemberData",
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])

  return result
}




export const getOneBookingDetails = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!id) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: {
          _id: id,
        },
      },
      {
        $lookup: {
          from: "teamMembers",
          localField: "serviceMemberId",
          foreignField: "_id",
          as: "serviceMemberData",
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customerData",
        },
      },
      {
        $unwind: "$customerData",
      },
    ])

  return result[0]
}

export const acceptBooking = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  const gUser = await generalLogin()
  return await gUser?.callFunction("bookings", {
    functionName: "acceptBooking",
    params: { id },
  })
}

export const cancelBookings = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  const gUser = await generalLogin()

  return await gUser?.callFunction("bookings", {
    functionName: "cancelBooking",
    params: { id },
  })
}

export const BookingStatusUpdate = async ({ itemId, orderStatus, endTime }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!itemId || !orderStatus) {
    return
  }

  const gUser = await generalLogin()
  const params = {
    id: itemId,
    orderStatus: orderStatus,
    endTime,
  }

  const result = await gUser?.callFunction("bookings", {
    functionName: "bookingStatusChange",
    params: params,
  })

  return result
}

export const getLastBooking = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "teamMembers",
          localField: "serviceMemberId",
          foreignField: "_id",
          as: "serviceMemberData",
        },
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ])

  return result[0]
}

export const getBookingsThisyear = async ({
  startOfYear,
  endOfYear,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfYear, endOfYear);
  if (!providerId || !startOfYear || !endOfYear) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        placedOn: { $gt: startOfYear },
      },
      {
        placedOn: { $lt: endOfYear },
      },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
  return result
}

export const getBookingsThisMonth = async ({
  startOfMonth,
  endOfMonth,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfMonth, endOfMonth);
  if (!providerId || !startOfMonth || !endOfMonth) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        placedOn: { $gt: startOfMonth },
      },
      {
        placedOn: { $lt: endOfMonth },
      },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
  return result
}

export const getBookingsThisWeek = async ({
  startOfWeek,
  endOfWeek,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startOfWeek, endOfWeek);
  if (!providerId || !startOfWeek || !endOfWeek) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        placedOn: { $gt: startOfWeek },
      },
      {
        placedOn: { $lt: endOfWeek },
      },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }
  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
  return result
}

export const getBookingsThisDay = async ({
  startTodays,
  endTodays,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log(providerId, startTodays, endTodays);
  if (!providerId || !startTodays || !endTodays) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [
      {
        placedOn: { $gt: startTodays },
      },
      {
        placedOn: { $lt: endTodays },
      },
      { status: "completed" },
      { providerId: providerId },
    ],
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }
  if (branchId) {
    query.$and.push({ branchId: branchId })
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
  return result
}

export const getBookingServices = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }
  const query = {
    $and: [{ providerId: providerId }, { isActive: true }],
  }

  const result = await getDBInstance(app)
    .collection("services")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "serviceCategories",
          localField: "categoryId",
          foreignField: "_id",
          as: "serviceCategory",
        },
      },

      {
        $sort: {
          _id: -1,
        },
      },
    ])

  return result
}

export const bookingCreate = async ({
  branchId,
  customerId,
  providerId,
  providerType,
  items,
  payments,
  serviceIds,
  startTime,
  estimatedEndTime,
  endTime,
  bookedDate,
  completedOn,
  serviceMemberId,
  totalDuration,
  status,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId)
  }

  if (typeof customerId === "string") {
    customerId = new ObjectId(customerId)
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  if (bookedDate) {
    bookedDate = new Date(bookedDate)
  }

  const gUser = await generalLogin()

  return await gUser?.callFunction("bookings", {
    functionName: "orderCreate",
    params: {
      branchId,
      customerId,
      providerId,
      providerType,
      items,
      payments,
      serviceIds,
      startTime,
      estimatedEndTime,
      endTime,
      bookedDate,
      completedOn,
      serviceMemberId,
      totalDuration,
      status,
    },
  })
}

export const getAudienceBookings = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  const result1 = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  const combinedResults = [...result, ...result1]

  // Use a Set to eliminate duplicate customers
  const uniqueCustomersSet = new Set()

  const uniqueCustomers = combinedResults?.filter(entry => {
    if (!uniqueCustomersSet.has(entry.customer?._id?.toString())) {
      uniqueCustomersSet.add(entry.customer?._id?.toString())
      return true
    }
    return false
  })

  return uniqueCustomers
}

export const getServiceMembers = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("teamMembers")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            value: "$_id",
            label: "$name",
          },
        },
      },
    ])
  return result
}

export const getCouponProducts = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const query = {
    $and: [{ providerId: providerId }],
  }

  const result = await getDBInstance(app)
    .collection("menus")
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
  return result
}

export const getCouponCustomer = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!providerId) {
    return
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId)
  }

  const result = await getDBInstance(app)
    .collection("chat")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  const result1 = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $group: {
          _id: "$customerId",
          customer: { $first: "$customer" },
        },
      },
    ])

  const combinedResults = [...result, ...result1]

  // Use a Set to eliminate duplicate customers
  const uniqueCustomersSet = new Set()

  const uniqueCustomers = combinedResults?.filter(entry => {
    if (!uniqueCustomersSet.has(entry.customer?._id?.toString())) {
      uniqueCustomersSet.add(entry.customer?._id?.toString())
      return true
    }
    return false
  })

  return uniqueCustomers
}

export const AvailableSlotsChecking = async ({
  id,
  duration,
  bookedDate,
  currentTimeHours,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  if (!id) {
    return
  }

  if (!duration) {
    return
  }

  if (!bookedDate) {
    return
  }

  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const gUser = await generalLogin()

  // console.log("db", bookedDate, id.toString(), duration);

  return await gUser?.callFunction("SlotsAvailabilityChecking", {
    functionName: "availabilityChecking",
    params: {
      id,
      duration,
      bookedDate,
      currentTimeHours,
    },
  })
}

export const UpdateServiceTime = async ({ bookingId, data }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (!bookingId || !data) {
    return
  }

  if (typeof bookingId === "string") {
    bookingId = new ObjectId(bookingId)
  }

  const result = await getDBInstance(app)
    .collection("bookings")
    .updateOne(
      {
        _id: bookingId,
      },
      {
        $set: {
          startTime: data.startTime,
          estimatedEndTime: data.estimatedEndTime,
          endTime: null,
          bookedDate: new Date(data.bookedDate),
          "metadata.modifiedAt": new Date(),
        },
      }
    )

  return result
}

export const sendEmailInvoiceBookingConfirmation = async ({ bookingId }) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log("bookingId==", bookingId)
  if (typeof bookingId === "string") {
    bookingId = new ObjectId(bookingId)
  }
  const params = {
    bookingId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("booking_notifications", {
    functionName: "bookingCompleteEmailNotification",
    params: params,
  })
  return result
}

export const sendEmailBookingAccept = async ({ bookingId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof bookingId === "string") {
    bookingId = new ObjectId(bookingId)
  }
  const params = {
    bookingId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("booking_notifications", {
    functionName: "bookingAcceptEmailNotification",
    params: params,
  })
  return result
}

export const sendEmailBookingUpdate = async ({ bookingId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof bookingId === "string") {
    bookingId = new ObjectId(bookingId)
  }
  const params = {
    bookingId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("booking_notifications", {
    functionName: "bookingUpdateEmailNotification",
    params: params,
  })
  return result
}

export const sendEmailBookingCancellation = async ({ bookingId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof bookingId === "string") {
    bookingId = new ObjectId(bookingId)
  }
  const params = {
    bookingId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("booking_notifications", {
    functionName: "bookingCancelEmailNotification",
    params: params,
  })
  return result
}




export const sendEmailOrderAccept = async ({ orderId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof orderId === "string") {
    orderId = new ObjectId(orderId)
  }
  const params = {
    orderId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("orderEmail_notifications", {
    functionName: "orderAcceptEmailNotification",
    params: params,
  })
  return result
}





// export const sendEmailInvoiceOrderConfirmation = async ({ orderId }) => {
//   if (!app || !app.currentUser) {
//     return
//   }
//   if (typeof orderId === "string") {
//     orderId = new ObjectId(orderId)
//   }
//   const params = {
//     orderId,
//   }
//   const gUser = await generalLogin()
//   const result = await gUser?.callFunction("order_notifications", {
//     functionName: "orderCompleteEmailNotification",
//     params: params,
//   })
//   return result
// }




export const sendEmailInvoiceOrderConfirmation = async ({ orderId }) => {
  if (!app || !app.currentUser) {
    return
  }
  if (typeof orderId === "string") {
    orderId = new ObjectId(orderId)
  }
  const params = {
   orderId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("orderEmail_notifications", {
    functionName: "orderCompleteEmailNotification",
    params: params,
  })
  return result
}


export const sendEmailOrderCancellation = async ({ orderId }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof orderId === "string") {
    orderId = new ObjectId(orderId)
  }
  const params = {
    orderId,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("orderEmail_notifications", {
    functionName: "orderCancelEmailNotification",
    params: params,
  })
  return result
}

