import React, { useState, useRef, useEffect } from "react"
import "../../css/services.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import { useNavigate, useParams } from "react-router-dom"

import { useCurrentProvider, useOneCms } from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { addCMS, updateCMS } from "common/Services/dbServices"
import { EditorState, convertFromRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const AddCms = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  })
  const [checked, setChecked] = React.useState(true)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [cmsData, setCmsData] = useState("")

  const currentProvider = useCurrentProvider()
  const handleChange = event => {
    setChecked(event.target.checked)
  }

  const {
    data,
    isLoading: isLoadingData,
    refetch,
    isSuccess,
  } = useOneCms({
    id,
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewCMS = async data => {
    console.log("data:", data)
    if (id) {
      setIsLoading(true)
      try {
        const result = await updateCMS({
          itemId: id,
          data,
          content: cmsData,
          isActive: checked,
        })
        // console.log("update:", result)
        setIsLoading(false)
        refetch()
        navigate("/cms")
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    } else {
      setIsLoading(true)
      try {
        const result = await addCMS({
          providerId: currentProvider?.data?._id,
          data,
          content: cmsData,
          isActive: checked,
        })
        console.log("add:", result)
        setIsLoading(false)
        navigate("/cms")
      } catch (error) {
        setIsLoading(false)
        // console.log(error)
      }
    }
  }

  useEffect(() => {
    if (id) {
      setValue("name", data?.name?.en)
      setChecked(data?.isActive)
      setValue("cmsData", data?.content)
      setCmsData(data?.content)
    }
  }, [isSuccess])

  const onEditorStateChange = editorState => {
    const contentState = convertFromRaw(editorState)
    setEditorState(editorState)
  }

  if (isLoadingData) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  return (
    <div className="service-div">
      <h5 className="service-edit-title">
        {id ? `Edit CMS Page` : `Add New CMS Page`}
      </h5>{" "}
      <form>
        <div className="service-text-field">
          <div className="service-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("name", { required: true })}
              error={errors.name ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.name && errors.name?.message
                ? errors.name?.message.toString()
                : null}
            </Typography>
          </div>

          <div className="service-text-field-row">
            <CKEditor
              editor={ClassicEditor}
              data={cmsData}
              onChange={(event, editor) => {
                const data = editor.getData()
                // setValue("cmsData", data)
                setCmsData(data)
              }}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>

          <div>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label="Is Active"
              labelPlacement="start"
            />
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewCMS)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddCms
