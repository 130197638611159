import React from "react"
import "../../css/booking.css"
import { useParams, useNavigate } from "react-router-dom"
import {
  useOneBookingDetails,
  useAvailableSlotsChecking,
} from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import ListGroup from "react-bootstrap/ListGroup"
import {
  format24ToTime,
  formatCurrency,
  formatDate,
  formatDateOnly,
} from "common/Services/functions"
import { LoadingButton } from "@mui/lab"
import { Button } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import { Snackbar, Alert } from "@mui/material"
import dayjs from "dayjs"
import CircularProgress from "@mui/material/CircularProgress"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import {
  acceptBooking,
  sendEmailBookingAccept,
  cancelBookings,
  sendEmailBookingCancellation,
  sendEmailBookingUpdate,
  sendEmailInvoiceBookingConfirmation,
  BookingStatusUpdate,
  UpdateServiceTime,
} from "common/Services/dbServices"

const BookingDetails = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [cancelLoading, setCancelLoading] = React.useState(false)
  const { id } = useParams()
  const { data, isLoading, refetch } = useOneBookingDetails({ id })
  const [openDialog, setOpenDialog] = React.useState(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState("")
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success")
  const [openModal, setOpenModal] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState("")
  const [selectedSlot, setSelectedSlot] = React.useState("")
  const [openDateDialog, setOpenDateDialog] = React.useState(false)

  const currentDate = dayjs()
  const nextThreeDates = []

  // Add dates to nextThreeDates
  for (let i = 0; i < 4; i++) {
    const nextDate = currentDate.add(i, "day")
    const formattedDate = {
      name: nextDate.format("DD ddd"),
      value: new Date(nextDate),
    }
    nextThreeDates.push(formattedDate)
  }

  // Function to find if the selected date exists in the nextThreeDates
  const findSelectedDate = () => {
    const foundDate = nextThreeDates.find(
      dateObj =>
        dayjs(dateObj?.value).format("YYYY-MM-DD") ===
        dayjs(selectedDate).format("YYYY-MM-DD")
    )

    if (foundDate) {
      return true
    } else {
      return false
    }
  }

  const handleCloseDialog = () => {
    setOpenDateDialog(false)
  }

  const slotsData = useAvailableSlotsChecking({
    id: data?.serviceMemberData[0]?._id,
    duration: data?.items[0].duration,
    bookedDate: selectedDate ? new Date(selectedDate) : null,
    currentTimeHours: currentDate.format("HH:mm"),
  })

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  console.log("data=============", data)

  const handleBookingStatusUpdate = async ({ itemId, orderStatus }) => {
    setLoading(true)
    try {
      const result = await BookingStatusUpdate({
        itemId,
        orderStatus,
        endTime: dayjs().format("HH:mm"),
      })
      if (result?.modifiedCount > 0) {
        refetch()
        setLoading(false)
        try {
          await sendEmailInvoiceBookingConfirmation({ bookingId: itemId })
          console.log("Completed email sent successfully.")
        } catch (emailError) {
          console.error("Error sending booking complete email:", emailError)
          alert("Booking Completed, but email notification failed.")
        }
      } else {
        alert("Failed to Complete booking. Please try again.")
      }
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  const handleAcceptBooking = async () => {
    setLoading(true)
    try {
      const result = await acceptBooking({ id })
      if (result?.modifiedCount > 0) {
        refetch()
        setLoading(false)
        try {
          await sendEmailBookingAccept({ bookingId: id })
          console.log("Accept email sent successfully.")
        } catch (emailError) {
          console.error("Error sending accept email:", emailError)
          alert("Booking accepted, but email notification failed.")
        }
      } else {
        alert("Failed to accept booking. Please try again.")
      }
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleCancelBooking = async () => {
    setCancelLoading(true)
    try {
      const result = await cancelBookings({
        id: id,
      })
      if (result?.modifiedCount > 0) {
        setOpenDialog(false)
        refetch()
        setCancelLoading(false)
        setSnackbarMessage("Order canceled successfully!")
        setSnackbarSeverity("success")
        setSnackbarOpen(true)
        navigate("/bookings")
        try {
          await sendEmailBookingCancellation({ bookingId: id })
        } catch (emailError) {
          console.error("Error sending cancel email:", emailError)
          alert("Booking cancelled, but email notification failed.")
        }
      } else {
        alert("Failed to cancel booking. Please try again.")
      }
    } catch (error) {
      setCancelLoading(false)
      setSnackbarMessage("Error canceling the order. Please try again.")
      setSnackbarSeverity("error")
      setSnackbarOpen(true)
    }
  }

  const handleEditTime = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // saving the edited data
  const handleSave = async () => {
    if (!selectedDate || !selectedSlot) {
      alert("Please select both a date and a time slot.")
      return
    }

    const updatedData = {
      startTime: selectedSlot.start,
      estimatedEndTime: selectedSlot.end,
      bookedDate: dayjs(selectedDate),
    }

    try {
      const result = await UpdateServiceTime({
        bookingId: data._id,
        data: updatedData,
      })

      if (result?.modifiedCount > 0) {
        alert("Booking time updated successfully.")
        setOpenModal(false)
        // Trigger email notification after successful update
        try {
          await sendEmailBookingUpdate({ bookingId: data._id })
          console.log("Update email sent successfully.")
        } catch (emailError) {
          console.error("Error sending update email:", emailError)
          alert("Booking updated, but email notification failed.")
        }
      } else {
        alert("Failed to update booking. Please try again.")
      }
    } catch (error) {
      console.error("Error updating booking:", error)
      alert("An error occurred while updating the booking.")
    }
  }

  return (
    <>
      <div className="booking-details" style={{ marginTop: "20px" }}>
        <div className="order_update_btn_div">
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
            }}
            style={{ fontFamily: "outfit", margin: "8px" }}
            variant="contained"
            onClick={() => navigate(-1 || `/bookings`)}
          >
            Back
          </Button>

          {data?.status == "pending" ? (
            <div style={{ display: "flex", gap: "10px" }}>
              {/* Cancel Order Button */}
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 130,
                    height: 35,
                    backgroundColor: "#d32f2f",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#d32f2f",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#d32f2f",
                  },
                }}
                style={{ fontFamily: "outfit", color: "white" }}
                variant="contained"
                onClick={() => setOpenDialog(true)}
              >
                Cancel Order
              </Button>

              {/* Accept Order Button */}
              {loading ? (
                <LoadingButton
                  size="small"
                  color="secondary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      width: 130,
                      height: 35,
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                    },
                  }}
                  style={{ fontFamily: "outfit" }}
                  variant="contained"
                >
                  <span> Accept Order</span>
                </LoadingButton>
              ) : (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      width: 130,
                      height: 35,
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      width: 200,
                      height: 35,
                      backgroundColor: "#333547",
                    },
                  }}
                  style={{ fontFamily: "outfit" }}
                  variant="contained"
                  onClick={() => handleAcceptBooking()}
                >
                  Accept Order
                </Button>
              )}
            </div>
          ) : (
            ""
          )}

          {data?.status == "accepted" ? (
            loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                }}
                style={{ fontFamily: "outfit" }}
              >
                <span> Mark As Completed</span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                onClick={() =>
                  handleBookingStatusUpdate({
                    itemId: id,
                    orderStatus: "completed",
                  })
                }
              >
                Mark As Completed
              </Button>
            )
          ) : (
            ""
          )}
        </div>
        <ListGroup>
          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Reference:</h6>
                <h6>{data?.refNumber}</h6>
              </div>
              <div className="booking-detail-element">
                <h6>Placed On:</h6>
                <h6>{formatDate(data?.placedOn)}</h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Booking Date:</h6>
                <h6>{formatDateOnly(data?.bookedDate)}</h6>
              </div>{" "}
              <div className="booking-detail-element">
                <h6>Assigned To:</h6>
                <h6>
                  {data?.serviceMemberData?.length > 0 &&
                  data?.serviceMemberData[0]
                    ? data?.serviceMemberData[0]?.name
                    : `-`}
                </h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Customer Name:</h6>
                <h6>{`${data?.customerData?.firstName} ${data?.customerData?.lastName}`}</h6>
              </div>{" "}
              <div className="booking-detail-element">
                <h6>Customer Number:</h6>
                <h6>
                  {data?.customerData && data?.customerData?.number
                    ? data?.customerData?.number
                    : `-`}
                </h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Start Time:</h6>
                <h6>{format24ToTime(data?.startTime)}</h6>
              </div>
              {data?.endTime ? (
                <div className="booking-detail-element">
                  <h6>End Time:</h6>
                  <h6>{format24ToTime(data?.endTime)}</h6>
                </div>
              ) : (
                <div className="booking-detail-element">
                  <h6>Estimated End Time:</h6>
                  <h6>{format24ToTime(data?.estimatedEndTime)}</h6>
                </div>
              )}
              {data?.status !== "completed" && (
                <button
                  onClick={() => handleEditTime()}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#000000",
                  }}
                >
                  <i className="fas fa-edit" style={{ fontSize: "1.2rem" }}></i>
                </button>
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Booking Status:</h6>
                <h6>{data?.status}</h6>
              </div>{" "}
              <div className="booking-detail-element">
                <h6>Payment Status:</h6>
                <h6>{data?.paymentStatus}</h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Item Total Amount:</h6>
                <h6>{formatCurrency(data?.payments?.itemTotal || 0)}</h6>
              </div>
              <div className="booking-detail-element">
                <h6>Total Amount Payable:</h6>
                <h6>
                  {formatCurrency(
                    data?.amount || data?.payments?.amountPayable || 0
                  )}
                </h6>
              </div>
            </div>
          </ListGroup.Item>
          {data?.completedOn ? (
            <ListGroup.Item>
              <div
                className="booking-detail-row"
                style={{ fontFamily: "outfit" }}
              >
                <div className="booking-detail-element">
                  <h6>Completed On:</h6>
                  <h6>{formatDate(data?.completedOn)}</h6>
                </div>
              </div>
            </ListGroup.Item>
          ) : (
            ""
          )}
        </ListGroup>
        <ListGroup>
          <ListGroup.Item style={{ padding: 0 }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={tableHeadStyle}
                      align="left"
                      style={{ fontFamily: "outfit" }}
                    >
                      Services
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Service Category
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Duration
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Image
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.items?.map(row => (
                    <TableRow key={row?._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${row?.name?.en}`}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                          }}
                        >
                          {row?.getServiceCategory?.map((option, index) => (
                            <p className="order-variant-p" key={index}>
                              {option?.name?.en}
                            </p>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${row?.duration?.HH} hr : ${row?.duration?.MM} min `}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        <img
                          src={row?.image?.url}
                          style={{ width: "60px", height: "60px" }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${formatCurrency(row?.salePrice || row?.price)}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ListGroup.Item>{" "}
        </ListGroup>
        {/* Modal for Cancel Order Confirmation */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "#f0f8ff",
              borderRadius: "12px",
              padding: "20px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontFamily: "outfit",
              fontSize: "1.2rem",
              color: "#333",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Cancel Order
          </DialogTitle>
          <DialogContent
            sx={{
              fontFamily: "outfit",
              fontSize: "1rem",
              color: "#444",
              textAlign: "center",
            }}
          >
            Are you sure you want to cancel this order?
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              paddingTop: "15px",
            }}
          >
            <Button
              onClick={() => setOpenDialog(false)}
              color="primary"
              sx={{
                fontFamily: "outfit",
                backgroundColor: "#d32f2f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
            >
              No
            </Button>
            {cancelLoading ? (
              <LoadingButton
                size="small"
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                sx={{
                  fontFamily: "outfit",
                  color: "#1976d2",
                  borderColor: "#1976d2",
                }}
              >
                Yes
              </LoadingButton>
            ) : (
              <Button
                onClick={handleCancelBooking}
                color="secondary"
                autoFocus
                disabled={cancelLoading}
                sx={{
                  fontFamily: "outfit",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
      {/* Snackbar Component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Modal for selecting Date */}
      <Dialog
        open={openDateDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex justify-center">
              <DateCalendar
                value={selectedDate}
                onChange={newValue => {
                  setSelectedDate(newValue)
                  setOpenDateDialog(false)
                }}
              />
            </div>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>

      {/* Modal for Editing Time slot */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Edit Booking Time</DialogTitle>
        <DialogContent>
          <div className="flex flex-col justify-center pb-4 border-b rounded-lg">
            <h6 className="py-4 flex justify-start font-medium text-[14px]">
              Preferred Date
            </h6>
            <div className="flex gap-4 justify-start items-center overflow-x-auto">
              {nextThreeDates.map((date, index) => (
                <button
                  className={`min-w-[50px] flex flex-col items-center text-center px-3 py-2 border rounded-md cursor-pointer transition-transform duration-300 hover:scale-105 ${
                    dayjs(date?.value).format("YYYY-MM-DD") ===
                    dayjs(selectedDate).format("YYYY-MM-DD")
                      ? "bg-blue-500 text-gray-800 border-blue-500"
                      : "bg-white text-gray-800 border-gray-300 hover:bg-blue-100"
                  }`}
                  key={index}
                  onClick={() => {
                    setSelectedDate(date?.value)
                  }}
                >
                  <span className="font-medium text-sm">
                    {dayjs(date?.value).format("DD")}
                  </span>
                  <span className="text-xs text-gray-600">
                    {dayjs(date?.value).format("ddd")}
                  </span>
                </button>
              ))}
              {selectedDate && !findSelectedDate() ? (
                <button
                  className="min-w-[50px] flex flex-col items-center text-center px-3 py-2 bg-blue-500 text-white border border-blue-500 rounded-md cursor-pointer transition-transform duration-300 hover:scale-105"
                  onClick={() => {
                    setSelectedDate(null)
                    setOpenDialog(true)
                  }}
                >
                  {dayjs(selectedDate)?.format("DD")}
                  <span className="text-xs text-white">
                    {dayjs(selectedDate)?.format("ddd")}
                  </span>
                </button>
              ) : (
                <button
                  className="min-w-[50px] flex justify-center items-center px-3 py-2 border rounded-md cursor-pointer transition-transform duration-300 hover:scale-105 hover:bg-blue-100"
                  onClick={() => {
                    setSelectedDate(null)
                    setOpenDateDialog(true)
                  }}
                >
                  <CalendarMonthIcon className="text-gray-600" />
                </button>
              )}
            </div>
          </div>

          <div className="flex flex-col justify-center pb-4 border-b rounded-lg">
            <h6 className="m-0 py-4 flex justify-start text-[14px] font-medium">
              Available Slots
            </h6>
            <div className="w-full flex flex-wrap gap-3 justify-center p-2.5">
              {slotsData?.isLoading ? (
                <div className="flex justify-center">
                  <CircularProgress size={30} color="success" />
                </div>
              ) : slotsData?.data?.length === 0 ? (
                <div className="flex justify-center">
                  <h6 className="text-gray-500">No Time Slots Available</h6>
                </div>
              ) : (
                slotsData?.data?.map(item => (
                  <button
                    key={`${item.start}-${item.end}`}
                    className={`px-4 py-2 rounded-full text-sm font-medium border transition-colors duration-300 cursor-pointer ${
                      item?.start === selectedSlot?.start &&
                      item?.end === selectedSlot?.end
                        ? "bg-blue-500 text-gray-800 border-blue-500"
                        : "bg-white text-gray-800 border-gray-300 hover:bg-green-100"
                    }`}
                    onClick={() => {
                      setSelectedSlot(item)
                    }}
                  >
                    {`${dayjs(item?.start, "HH:mm").format("h:mm A")} - ${dayjs(
                      item?.end,
                      "HH:mm"
                    ).format("h:mm A")}`}
                  </button>
                ))
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BookingDetails
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}
