import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Button, IconButton } from "@mui/material"
import { LottieLoading } from "common/LottieLoading"
import { useNavigate } from "react-router-dom"
import {
  useCurrentProvider,
  useBookings,
  useTeamMembers,
  useServices,
} from "common/Hooks/queryCalls"
import { formatDateOnly, format24ToTime } from "common/Services/functions"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import moment from "moment"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import Box from "@mui/material/Box"
import { Calendar, momentLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

moment.locale("en-GB")
const localizer = momentLocalizer(moment)

const Bookings = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const currentProvider = useCurrentProvider()

  const [anchorEl, setAnchorEl] = useState(null)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [btnprimary2, setBtnprimary2] = useState(false)
  const [btnprimary3, setBtnprimary3] = useState(false)
  const [btnprimary4, setBtnprimary4] = useState(false)
  const [btnprimary5, setBtnprimary5] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("all")
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("all")
  const [selectedDate, setSelectedDate] = useState("all")
  const [selectedTeamMember, setSelectedTeamMember] = useState({
    _id: undefined,
    name: "all",
  })
  const [selectedService, setSelectedService] = useState({
    _id: undefined,
    name: { en: "all" },
  })
  const [currentYear, setCurrentYear] = useState(moment().format("yyyy"))
  const [currentMonth, setCurrentMonth] = useState(moment())
  const [firstDate, setFirstDate] = useState()
  const [lastDate, setLastDate] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const startOfYear = moment(currentYear).startOf("year").toDate()
  const endOfYear = moment(currentYear).endOf("year").toDate()
  const startOfMonth = moment(currentMonth).startOf("month").toDate()
  const endOfMonth = moment(currentMonth).endOf("month").toDate()
  const startOfWeek = moment(currentMonth).startOf("isoweek").toDate()
  const endOfWeek = moment(currentMonth).endOf("isoweek").toDate()
  const startTodays = moment(currentMonth).startOf("day").toDate()
  const endTodays = moment(currentMonth).endOf("day").toDate()

  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const [eventsData, setEventsData] = useState([])

  const { data, isLoading, refetch } = useBookings({
    providerId: currentProvider?.data?._id,
    status: selectedStatus,
    paymentStatus: selectedPaymentStatus,
    startDate: startDate ? moment(startDate).startOf("day").toDate() : null,
    endDate: endDate ? moment(endDate).endOf("day").toDate() : null,
    assignedTo: selectedTeamMember._id,
    service: selectedService._id,
  })
  // console.log("data====", data)

  const { data: teamData, isLoading: teamLoading } = useTeamMembers({
    providerId: currentProvider?.data?._id,
  })

  const { data: serviceData, isLoading: serviceLoading } = useServices({
    providerId: currentProvider?.data?._id,
  })

  useEffect(() => {
    if (data) {
      const tempEventData = data?.map(item => ({
        id: item?._id,
        title:
          item?.serviceMemberData?.length > 0 && item?.serviceMemberData[0]
            ? item?.serviceMemberData[0]?.name
            : `-`,
        start: combineDateAndTime(item?.bookedDate, item?.startTime),
        end: combineDateAndTime(item?.bookedDate, item?.estimatedEndTime),
      }))

      setEventsData(tempEventData)
    }
  }, [data])

  function combineDateAndTime(dateStr, timeStr) {
    const datePart = new Date(dateStr).toISOString().split("T")[0] // YYYY-MM-DD

    const combinedDateTimeStr = `${datePart}T${timeStr}:00.000Z` // YYYY-MM-DDTHH:MM:SSZ format

    const combinedDate = new Date(combinedDateTimeStr)

    return combinedDate
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const handleDateClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleDateClose = () => {
    setAnchorEl(null)
  }
  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
  }
  const handleClearDateChange = event => {
    setStartDate("")
    setFirstDate("")
    setLastDate("")
    setEndDate("")
  }
  const openPrice = Boolean(anchorEl)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  return (
    <>
      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">Bookings</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Diving into Bookings</li>
            </ol>
          </div>
        </div>
      </div>
      <div style={{ minHeight: "500px" }}>
        {" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              flexWrap: "wrap",
              paddingBottom: "10px",
            }}
          >
            {" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                {" "}
                Booking Status
              </span>
              <div className="btn-group">
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn "
                    style={{
                      backgroundColor: "#333547",
                      color: "white",
                      minWidth: "100px",
                    }}
                  >
                    {`${selectedStatus.replace(
                      /^./,
                      selectedStatus[0].toUpperCase()
                    )}`}{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setSelectedStatus("all")}>
                      All
                    </DropdownItem>
                    <DropdownItem onClick={() => setSelectedStatus("pending")}>
                      Pending
                    </DropdownItem>
                    <DropdownItem onClick={() => setSelectedStatus("accepted")}>
                      Accepted
                    </DropdownItem>
                    <DropdownItem onClick={() => setSelectedStatus("ready")}>
                      Ready
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setSelectedStatus("completed")}
                    >
                      Completed
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setSelectedStatus("cancelled")}
                    >
                      Cancelled
                    </DropdownItem>
                    <DropdownItem onClick={() => setSelectedStatus("refunded")}>
                      Refunded
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                Payment Status
              </span>
              <div className="btn-group">
                <Dropdown
                  isOpen={btnprimary2}
                  toggle={() => setBtnprimary2(!btnprimary2)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn"
                    style={{
                      backgroundColor: "#333547",
                      color: "white",
                      minWidth: "100px",
                    }}
                  >
                    {`${selectedPaymentStatus.replace(
                      /^./,
                      selectedPaymentStatus[0].toUpperCase()
                    )} `}{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {" "}
                    <DropdownItem
                      onClick={() => setSelectedPaymentStatus("all")}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setSelectedPaymentStatus("pending")}
                    >
                      Pending
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setSelectedPaymentStatus("paid")}
                    >
                      Paid
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                Date
              </span>
              <div className="btn-group">
                {/* <Button
                    id="basic-button"
                    aria-controls={openPrice ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openPrice ? "true" : undefined}
                    onClick={handleDateClick}
                    variant="contained"
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{
                      "@media (max-width: 600px)": {
                        // fontSize: "0.6rem",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        backgroundColor: "#333547",
                        height: "35px",
                        "&:hover": {
                          backgroundColor: "#333547",
                        },
                      },
                      "@media (min-width: 601px) and (max-width: 960px)":
                      {
                        // fontSize: "0.8rem",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        backgroundColor: "#333547",
                        height: "35px",
                        "&:hover": {
                          backgroundColor: "#333547",
                        },
                      },
                      "@media (min-width: 970px)": {
                        // fontSize: "0.8rem",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        backgroundColor: "#333547",
                        height: "35px",
                        "&:hover": {
                          backgroundColor: "#333547",
                        },
                      },
                    }}
                  >
                    Date
                  </Button> */}
                <Dropdown
                  isOpen={btnprimary3}
                  toggle={() => setBtnprimary3(!btnprimary3)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn"
                    style={{
                      backgroundColor: "#333547",
                      color: "white",
                      minWidth: "100px",
                    }}
                  >
                    {`${selectedDate.replace(
                      /^./,
                      selectedDate[0].toUpperCase()
                    )} `}{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {" "}
                    <DropdownItem
                      onClick={() => {
                        handleDateChange(null, null)
                        setSelectedDate("All")
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleDateChange(startTodays, endTodays)
                        setSelectedDate("Today")
                      }}
                    >
                      Today
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleDateChange(startOfWeek, endOfWeek)
                        setSelectedDate("This Week")
                      }}
                    >
                      This Week
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleDateChange(startOfMonth, endOfMonth)
                        setSelectedDate("This Month")
                      }}
                    >
                      This Month
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleDateChange(startOfYear, endOfYear)
                        setSelectedDate("This Year")
                      }}
                    >
                      This Year
                    </DropdownItem>
                    <DropdownItem onClick={handleDateClick}>
                      Custom Date
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>{" "}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openPrice}
                  onClose={handleDateClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></MenuItem>
                  <MenuItem>
                    <label style={{ fontFamily: "outfit" }}>
                      Start Date:
                      <input
                        type="date"
                        value={firstDate}
                        onChange={event => setFirstDate(event.target.value)}
                        className="date-input"
                      />
                    </label>
                  </MenuItem>
                  <MenuItem>
                    <label style={{ fontFamily: "outfit" }}>
                      End Date:
                      <input
                        type="date"
                        value={lastDate}
                        onChange={event => setLastDate(event.target.value)}
                        className="date-input"
                      />
                    </label>
                  </MenuItem>
                  <MenuItem onClick={handleDateClose}>
                    <Button
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                          marginLeft: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "#333547",
                          fontWeight: "400",
                          height: "35px",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                          marginLeft: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "#333547",
                          fontWeight: "400",
                          height: "35px",
                          "&:hover": {
                            backgroundColor: "#535EB4",
                          },
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                          marginLeft: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "#333547",
                          fontWeight: "400",
                          height: "35px",
                          "&:hover": {
                            backgroundColor: "#535EB4",
                          },
                        },
                      }}
                      onClick={() => handleDateChange(firstDate, lastDate)}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                          marginLeft: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "#333547",
                          fontWeight: "400",
                          height: "35px",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                          marginLeft: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "#333547",
                          fontWeight: "400",
                          height: "35px",
                          "&:hover": {
                            backgroundColor: "#535EB4",
                          },
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                          marginLeft: "5px",
                          textTransform: "capitalize",
                          backgroundColor: "#333547",
                          fontWeight: "400",
                          height: "35px",
                          "&:hover": {
                            backgroundColor: "#535EB4",
                          },
                        },
                      }}
                      onClick={handleClearDateChange}
                    >
                      Clear
                    </Button>
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                Assigned To
              </span>
              <div className="btn-group">
                <Dropdown
                  isOpen={btnprimary4}
                  toggle={() => setBtnprimary4(!btnprimary4)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn"
                    style={{
                      backgroundColor: "#333547",
                      color: "white",
                      minWidth: "100px",
                    }}
                  >
                    {`${selectedTeamMember.name.replace(
                      /^./,
                      selectedTeamMember.name[0].toUpperCase()
                    )} `}{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  {teamData && (
                    <DropdownMenu>
                      {" "}
                      <DropdownItem
                        onClick={() =>
                          setSelectedTeamMember({ _id: undefined, name: "all" })
                        }
                      >
                        All
                      </DropdownItem>
                      {teamData.map(option => (
                        <DropdownItem
                          onClick={() =>
                            setSelectedTeamMember({
                              _id: option._id,
                              name: option.name,
                            })
                          }
                        >
                          {option.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  )}
                </Dropdown>{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
                Service
              </span>
              <div className="btn-group">
                <Dropdown
                  isOpen={btnprimary5}
                  toggle={() => setBtnprimary5(!btnprimary5)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn"
                    style={{
                      backgroundColor: "#333547",
                      color: "white",
                      minWidth: "100px",
                    }}
                  >
                    {`${selectedService.name.en.replace(
                      /^./,
                      selectedService.name.en[0].toUpperCase()
                    )} `}{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  {serviceData && (
                    <DropdownMenu>
                      {" "}
                      <DropdownItem
                        onClick={() =>
                          setSelectedService({
                            _id: undefined,
                            name: { en: "all" },
                          })
                        }
                      >
                        All
                      </DropdownItem>
                      {serviceData.map(option => (
                        <DropdownItem
                          onClick={() =>
                            setSelectedService({
                              _id: option._id,
                              name: option.name,
                            })
                          }
                        >
                          {option.name.en}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  )}
                </Dropdown>{" "}
              </div>
            </div>
          </div>
        </div>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{ backgroundColor: "#333547" }}
          >
            <Tab
              sx={tabHeadStyle}
              label="List View"
              {...a11yProps(0)}
              style={{ fontFamily: "outfit" }}
            />
            <Tab
              sx={tabHeadStyle}
              label="Calendar View"
              {...a11yProps(1)}
              style={{ fontFamily: "outfit" }}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  {data?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Bookings Available
                    </caption>
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Reference
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Booking Date
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Booking Status
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Assigned To
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Start Time
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Estimated End Time
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        End Time
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Payment Status
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(item => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={item._id}
                            onClick={() =>
                              navigate(`/booking-details/${item?._id}`)
                            }
                          >
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.refNumber}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {formatDateOnly(item?.bookedDate)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={
                                item?.status == "completed"
                                  ? { color: "#098041", fontFamily: "outfit" }
                                  : item.status == "pending"
                                  ? { color: "#FFC107", fontFamily: "outfit" }
                                  : item.status == "ready" ||
                                    item.status == "accepted"
                                  ? { color: "#3796F0", fontFamily: "outfit" }
                                  : { color: "#F44336", fontFamily: "outfit" }
                              }
                            >
                              {item?.status}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.serviceMemberData?.length > 0 &&
                              item?.serviceMemberData[0]
                                ? item?.serviceMemberData[0]?.name
                                : `-`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.startTime
                                ? format24ToTime(item?.startTime)
                                : `-`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.estimatedEndTime
                                ? format24ToTime(item?.estimatedEndTime)
                                : `-`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.endTime
                                ? format24ToTime(item?.endTime)
                                : `-`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              {item?.paymentStatus}
                            </TableCell>

                            <TableCell align="center">
                              {
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={() =>
                                      navigate(`/booking-details/${item?._id}`)
                                    }
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={tableFooterStyle}
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Calendar
              views={["day", "agenda", "work_week", "month"]}
              selectable
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={eventsData}
              style={{ height: "75vh" }}
              onSelectEvent={event => navigate(`/booking-details/${event?.id}`)}
            />
          </TabPanel>
        </SwipeableViews>
      </div>
    </>
  )
}

export default Bookings
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}

const tabHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
}
