import PropTypes from "prop-types"
import React, { useEffect, useCallback, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import envConfig from "../../env/env.json"
import { useCurrentProvider, useAllAudience } from "common/Hooks/queryCalls"
import logosmImg from "../../assets/images/logo-sm.png"
import CircularProgress from "@mui/material/CircularProgress"
import { Box } from "@mui/material"
import { IconButton } from "@mui/material"
import { Badge } from "@mui/material"
import ChatIcon from "@mui/icons-material/Chat"
// //fontAwsome
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'

// library.add(fas, faTwitter, faFontAwesome, faHatCowboy, faHatChef, faPlateUtensils)
//scheduling system

const SidebarContent = props => {
  const customer = useCurrentProvider()
  const location = useLocation()
  const ref = useRef()
  const path = location.pathname
  // console.log("customer:", customer)

  const { data: customers } = useAllAudience({
    providerId: customer?.data?._id,
  })

  const unreadMessageCount = customers
    ? customers.reduce((total, customer) => {
        const messageCount = Array.isArray(customer.message)
          ? customer.message.length
          : 0
        return total + messageCount
      }, 0)
    : 0

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = location.pathname
    const fullPath = pathName
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {customer?.isLoading ||
            // customer?.isRefetching ||
            customer?.data === undefined ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <CircularProgress color="inherit" size={30} />
              </Box>
            ) : null}
            {customer?.data?.type === "e-commerce" ? (
              <>
                <li>
                  <a
                    target="_blank"
                    href={` ${envConfig.SHOP_URL}p/${customer?.data?.branch?.prefix}`}
                    style={{
                      fontSize: "0.7rem",
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    Visit Store
                  </a>{" "}
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">1</span> */}
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/details" className="waves-effect">
                    <i className="ti-info-alt"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">1</span> */}
                    <span>{props.t("Details")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/categories" className="waves-effect">
                    <i className="ti-receipt"></i>
                    <span>{props.t("Categories")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/subCategories" className="waves-effect">
                    <i className="ti-dropbox-alt"></i>
                    <span>{props.t("Sub Categories")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/products" className="waves-effect">
                    <i className="ti-menu"></i>
                    <span>{props.t("Products")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/variants" className="waves-effect">
                    <i className="ti-archive"></i>
                    <span>{props.t("Attributes")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/stocks" className="waves-effect">
                    <i className="ti-panel"></i>
                    <span>{props.t("Stock List")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/reviews" className="waves-effect">
                    <i className="ti-comment-alt"></i>
                    <span>{props.t("Reviews")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/audience" className="waves-effect">
                    <i className="ti-comments"></i>
                    <span>{props.t("Audience")}</span>
                    <IconButton
                      onClick={() => {
                        navigate("/audience")
                      }}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={unreadMessageCount}
                        max={10}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#ff6161",
                            color: "white",
                          },
                        }}
                      >
                        <ChatIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/orders" className="waves-effect">
                    <i className="ti-archive"></i>
                    <span>{props.t("Orders")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/create-order" className="waves-effect">
                    <i className="ti-agenda"></i>
                    <span>{props.t("Create Order")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/revenue" className="waves-effect">
                    <i className="ti-money"></i>
                    <span>{props.t("Revenue")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/coupon" className="waves-effect">
                    <i className="ti-gift"></i>
                    <span>{props.t("Coupon")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {customer?.data?.type === "booking-services" ? (
              <>
                <li onClick={() => tToggle()}>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">1</span> */}
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/details" className="waves-effect">
                    <i className="ti-info-alt"></i>
                    <span>{props.t("Details")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/service-categories" className="waves-effect">
                    <i className="ti-receipt"></i>
                    <span>{props.t("Service Categories")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/services" className="waves-effect">
                    <i className="ti-menu"></i>
                    <span>{props.t("Services")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/team-members" className="waves-effect">
                    <i className="ti-comments"></i>
                    <span>{props.t("Team Members")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/audience" className="waves-effect">
                    <i className="ti-comments"></i>
                    <span>{props.t("Audience")}</span>
                    <IconButton>
                      <Badge
                        color="secondary"
                        badgeContent={unreadMessageCount}
                        max={10}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#ff6161",
                            color: "white",
                          },
                        }}
                      >
                        <ChatIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  </Link>
                </li>

                <li onClick={() => tToggle()}>
                  <Link to="/bookings" className="waves-effect">
                    <i className="ti-archive"></i>
                    <span>{props.t("Bookings")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/create-booking" className="waves-effect">
                    <i className="ti-archive"></i>
                    <span>{props.t("Create Bookings")}</span>
                  </Link>
                </li>
                <li onClick={() => tToggle()}>
                  <Link to="/revenue-booking" className="waves-effect">
                    <i className="ti-money"></i>
                    <span>{props.t("Revenue")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            <li onClick={() => tToggle()}>
              <Link to="/cms" className="waves-effect">
                <i className="ti-receipt"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">1</span> */}
                <span>{props.t("CMS")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
